import Cleave from 'cleave.js';
import 'cleave.js/dist/addons/cleave-phone.it.js';
import { ModalFatturazioneElettronica } from '../../components/modal-fatturazione-elettronica/modal-fatturazione-elettronica';
import { Window, _$, _Uikit, _moment } from '../../main';
import { Analytics } from '../../services/analytics/analytics';
import { CartService } from '../../services/cart-service/cart-service';
import { LocalStorage } from '../../services/local-storage/local-storage';
import { UserService } from '../../services/user-service/user-service';
import { AddressUser } from '../../services/user-service/user-service.interface';
import { BOOKING_SLOT_STATE, BookUtils } from '../../services/utils/book-utils';
import { GoogleUtils } from '../../services/utils/google-utils';
import { GoogleAddressUtils } from '../../services/utils/google-address-utils'
import { LOADER_POSITION, Utils } from '../../services/utils/utils';
import { ValidationUtils } from 'src/services/utils/validation-utils';
import { OnboardingManager } from 'src/services/utils/onboarding-manager';

export class CheckoutNew {
    public emailGuest: string = '';
    public phone: number;
    public codePhone: string = '';
    public codePromo: string = '';
    private _modeFormBilling;
    private _billingAddressSelected;
    private _listBillingAddress;
    private _billingAddressSelectedEdit;
    private _blackListed = false;
    private _deliveryForm;
    private _dataUserAddressPreview; //controllare la res dell'indirizzo getUserAddress => campo 'summary'
    private _globalMessageError = `<div class='message-error uk-text-danger uk-hidden'>Campo obbligatorio</div>`;
    private _addressDeliveryFormattedAddress = Window.cart && Window.cart.deliveryAddress && Window.cart.deliveryAddress.formattedAddress ? Window.cart.deliveryAddress.formattedAddress : '';
    private _addressDeliveryLine1 = Window.cart && Window.cart.deliveryAddress && Window.cart.deliveryAddress.line1 ? Window.cart.deliveryAddress.line1 : '';
    private _addressDeliveryLine2 = Window.cart != null && Window.cart.deliveryAddress != null && Window.cart.deliveryAddress.line2 != null && Window.cart.deliveryAddress.line2 != '' ? Window.cart.deliveryAddress.line2 : '';
    private _addressDeliveryModifying;
    private _myconadAddresses;
    private selectedMyconadAddressId;

    private _urlEdit: boolean;

    private origin: string;
    private valueEmail: string = Window.user ? Window.user.email : '';
    private valuePassword: string = '';

    private userName: string = Window.user && Window.user.nome ? Window.user.nome : '';
    private userSurname: string = Window.user && Window.user.cognome ? Window.user.cognome : '';
    private userPhone: string = '';/* : string = Window.user && Window.user.telefonoCellulare ? Window.user.telefonoCellulare : ''; */
    private userEmail: string = Window.user && Window.user.email ? Window.user.email : '';

    /* indirizzo salvato dall'offcanvas degli indirizzi */
    private addressSaveDeliveryOffcanvasHD = {};
    private checkoutPressed = false;

    /* Lettura attributo per apertura modale OOS aggiornamento quantità */
    private showModalUpdatedQuantity = _$('.primary-panel').attr('data-updated-entries') == 'true' ? true : false;
    
    constructor(
        private utils: Utils,
        private google: GoogleUtils,
        private userService: UserService,
        private localStorage: LocalStorage,
        private bookUtils: BookUtils,
        private modalFatturazioneElettronica: ModalFatturazioneElettronica,
        private cartService: CartService,
        private _analytics: Analytics,
        private _validationUtils: ValidationUtils,
        private googleAddressUtils: GoogleAddressUtils,
        private onboardingManager: OnboardingManager
    ) {
    }

    async nkOnInit() {

        if(this.missingDeliveryAddressFromCart() && Window.typeOfService == "HOME_DELIVERY"){
            _Uikit.modal('#modalCheckoutDeliveryAreaError').show();
        }
        
        if(Window.typeOfService && Window.typeOfService == "HOME_DELIVERY"){
            this.setupDeliveryForm();
        }

        if (Window.selectedTimeslotExpiration) {
            _Uikit.countdown('.countdown-timer', {
                date: new Date(Window.selectedTimeslotExpiration)
            });
        }

        this.openWarningAggiungiProdotti();

        this.resizeListener();

        _$('#checkout_envoice_input').prop('checked', false);

        _Uikit.util.on('#offcanvasCheckoutAddressDeliveryList', 'hidden', () => {
            _$('#updateAddressDelivery').prop('disabled', true);
        });

        _Uikit.util.on('#offcanvasCheckoutAddressDeliveryList', 'show', () => {
            this.sendAnalytics('pageview', 'offcanvasCheckoutAddressDeliveryList');
        });

        _Uikit.util.on('#offcanvasFatturazioneElettronica', 'show', () => {
            //reset messaggi di errore
            _$('#offcanvasFatturazioneElettronica').find('.uk-label-float').children('.message-error').addClass('uk-hidden');
        });

        _Uikit.util.on('#offcanvasFatturazioneElettronica', 'hidden', () => {
            if (!this._billingAddressSelected) {
                _$('#checkout_envoice_input').prop('checked', false);
            }
            _$('#checkout_envoice_input').prop('disabled', false);
        });

        _$('#personalForm input').on('focusout', () => {
            setTimeout(() => {
                this.sendAnalytics('erroreFormDatiCheckout', _$('#personalForm'));
            }, 100);
        });

        _$('#addressForm input').on('focusout', () => {
            setTimeout(() => {
                this.sendAnalytics('erroreFormDatiCheckout', _$('#addressForm'));
            }, 100);
        });

        _$('.main-content-body .box-indirizzo').find('.internal.ritiro').find('.edit').on('click', () => {
            this.sendAnalytics('sceltaModalitaModificaIndirizzo');
        });

        _$('#addressForm .edit.modifica').on('click', () => {
            this.sendAnalytics('modificaIndirizzo');
        });

        _$('#addressForm .edit.scegli-indirizzo').on('click', () => {
            this.sendAnalytics('selezionaIndirizzoSalvato', null);
        });


        if(_$('.tooltip-icon')){
            if(_$('.tooltip-icon').length){
                _$('.tooltip-icon').on('click', (event) => {
                    event.stopPropagation();
                });
            }
        }
        
        this.initEditMode();

        setTimeout(() => {
            if(_$('body').hasClass('page-CheckoutGuest')){
            }else{
                if(Window.typeOfService == "HOME_DELIVERY"){
                    _$('#modal-onboarding').find('#ordina-e-ritira').addClass('uk-hidden');
                }else{
                    _$('#modal-onboarding').find('#spesa-a-casa').addClass('uk-hidden');
                }
            }
            
        }, 100);

        if (_$('[nkInput="phone"]').length) {
            let cleavePhone = new Cleave('[nkInput="phone"]', {
                phone: true,
                phoneRegionCode: 'IT'
            });
        }

        this.utils.initFormValidator('personalForm');
        this.utils.initFormValidator('addressForm');

        await this.checkInitUserAddress();
        this.checkSalesConditionsError();

        //controllare in quale pagina siamo
        if (_$('body').find('.container-guest').length) {
            this.sendAnalytics('pageview', 'checkout-guest');
        } else if (_$('body').find('.checkout-container').length) {
            this.sendAnalytics('pageview', 'checkout-container');
        }

        _$('#modalPreautorizzazione').on('show', () => { this.sendAnalytics('infoPreautorizzazione', null); });

        this.fillPersonalForm();
        this.getUserBillingAddress('loadcheckout');
        this.localStorage.setItem('checkCGV', 'false');
        this.setAnalyticsModalModificaIndirizzo();
        this.checkPaymentOptions();
        this.wineptsIframeListener();

        if(_$('#warning-slot-expired-checkout').length){
            _$('#warning-slot-expired-checkout').find('.uk-button-primary').on('click', () => {
                this.sendAnalytics('clickCTAPrenotaAdessoPrenotazioneScaduta');
            });

            _$('#warning-slot-expired-checkout').on('show', () => {
                this.sendAnalytics('pageviewPopupPrenotazioneScaduta');
            });
        }

        _$('.section-codice-sconto .uk-card-type-coupon').on('show', () => {
            this.sendAnalytics('espandiCoupon');
        });
        this.setAnalyticsScontrino();
        this.manageFirstAutofocus();
        this.setModalQuantitaAggiornataAnalytics();
    }

    missingDeliveryAddressFromCart(): boolean{
        if(!Window.cart?.deliveryAddress?.latitude  ||
            !Window.cart?.deliveryAddress?.longitude ||
            !Window.cart?.deliveryAddress?.country?.isocode ||
            !Window.cart?.deliveryAddress?.line1 ||
            !Window.cart?.deliveryAddress?.formattedAddress ||
            !Window.cart?.deliveryAddress?.postalCode
            ){
                return true;
            }else{
                return false;
            }
    }
        
    manageFirstAutofocus(){
        let requiredElements = _$('#personalForm .require-autofocus-custom, #addressForm .require-autofocus-custom').filter((index, val) => {
            return _$(val).val().toString().trim() == "" && _$(val).prop('required');
        });
        if(requiredElements.length && Window.typeOfService == "HOME_DELIVERY"){
            let focusedElement = _$(requiredElements).first();
            focusedElement.trigger('focus');
            setTimeout(() => {
                let personalForm = _$(focusedElement).closest('form').prop('id') == 'personalForm';
                if(personalForm){
                    _$('html').scrollTop(0);
                }else{
                    let offset = _$('.component-header').height() + _$('.banner-timer-container').height();
                    _$('html').scrollTop(_$('#personalForm .require-autofocus-custom, #addressForm .require-autofocus-custom').filter((index, val) => {
                        return _$(val).val().toString().trim() == "" && _$(val).prop('required');
                    }).first().offset().top - offset - 64)
                }
            }, 1)
        }
    }

    setupDeliveryForm(){
        this._deliveryForm = {};
        let form = _$('#deliveryForm');
        this._deliveryForm['formattedAddress'] = this._addressDeliveryFormattedAddress;
        this._deliveryForm['cellphone'] = '';
        this._deliveryForm['firstName'] = '';
        this._deliveryForm['lastName'] = '';
        this._deliveryForm['floor'];
        this._deliveryForm['doorbell'] = '';
        this._deliveryForm['lift'] = false;
        this._deliveryForm['country'] = {
            'isocode': Window.cart?.deliveryAddress?.country?.isocode ? Window.cart.deliveryAddress.country.isocode : ''
        };
        this._deliveryForm['line1'] = Window.cart?.deliveryAddress?.line1;
        this._deliveryForm['line2'] = Window.cart?.deliveryAddress?.line2;
        this._deliveryForm['postalCode'] = Window.cart?.deliveryAddress?.postalCode;
        this._deliveryForm['town'] = Window.cart?.deliveryAddress?.town;
        this._deliveryForm['nickname'] = '';
        this._deliveryForm['latitude'] = Window.cart?.deliveryAddress?.latitude;
        this._deliveryForm['longitude'] = Window.cart?.deliveryAddress?.longitude;
        this._deliveryForm['reception'] = false;
        this._deliveryForm['building'] = '';
        this._deliveryForm['remarks'] = '';
    }

    setAnalyticsModalModificaIndirizzo(){
        _$('#modalModificaIndirizzo').find('a.uk-modal-close').on('click', () => {
            this.sendAnalytics('ctaAnnulla');
        });

        _$('#modalModificaIndirizzo').on('show', () => {
            this.sendAnalytics('pageViewModalModificaIndirizzo');
        })

        _$('#modalModificaIndirizzo').find('.uk-modal-close-default').on('click', () => {
            this.sendAnalytics('chiusuraModalModificaIndirizzo');
        })
    }

    /**
     * Controllo se serve la login persistente
     * @param origin
     */
    checkIdentityUser(origin?) {
        if (Window.user) {
            this.origin = origin;
            if (Window.ecTime && (new Date().getTime() - Window.ecTime) > Window.ecTimeThreshold) {
                _Uikit.offcanvas('#offcanvasLogin').show();
            } else {
                if (origin == 'addAddress') {
                    this.addNewUserAddress();
                }
            }
        }
    }

    addNewUserAddress() {
        this.sendAnalytics('aggiungiIndirizzo');
        _Uikit.modal("#modalAggiungiIndirizzo").show();
    }
    
    /**
     * Compilazione form dati personali
     */
    fillPersonalForm(){
        if(Window.typeOfService == "ORDER_AND_COLLECT"){
            if(Window.user){
                if(Window.cart && Window.cart.phone){
                    _$('#c_s1_firstName').val(Window.user.nome);
                    _$('#c_s1_lastName').val(Window.user.cognome);
                    _$('#c_s1_cellphone').val(Window.cart.phone);
                    _$('#c_s1_email').val(Window.user.email);
                    this.controlValueFieldInput(_$('#c_s1_firstName'), 'submitContactDetails', 'personalForm', 'c_s1_firstName');
                    this.controlValueFieldInput(_$('#c_s1_lastName'), 'submitContactDetails', 'personalForm', 'c_s1_lastName');
                    this.controlValueFieldInput(_$('#c_s1_cellphone'), 'submitContactDetails', 'personalForm', 'c_s1_cellphone');
                    this.userName = _$('#personalForm').find('#c_s1_firstName').val().toString();
                    this.userSurname = _$('#personalForm').find('#c_s1_lastName').val().toString();
                    this.userPhone = _$('#personalForm').find('#c_s1_cellphone').val().toString();
                    this.userEmail = _$('#personalForm').find('#c_s1_email').val().toString();
                }else{
                    _$('#c_s1_firstName').val(Window.user.nome);
                    _$('#c_s1_lastName').val(Window.user.cognome);
                    _$('#c_s1_email').val(Window.user.email);
                    this.controlValueFieldInput(_$('#c_s1_firstName'), 'submitContactDetails', 'personalForm', 'c_s1_firstName');
                    this.controlValueFieldInput(_$('#c_s1_lastName'), 'submitContactDetails', 'personalForm', 'c_s1_lastName');
                }
            }else{
                if(Window.cart && Window.cart.firstname && Window.cart.lastname && Window.cart.phone && Window.cart.email){
                    _$('#c_s1_firstName').val(Window.cart.firstname);
                    _$('#c_s1_lastName').val(Window.cart.lastname);
                    _$('#c_s1_cellphone').val(Window.cart.phone);
                    _$('#c_s1_email').val(Window.cart.email);
                    this.controlValueFieldInput(_$('#c_s1_firstName'), 'submitContactDetails', 'personalForm', 'c_s1_firstName');
                    this.controlValueFieldInput(_$('#c_s1_lastName'), 'submitContactDetails', 'personalForm', 'c_s1_lastName');
                    this.controlValueFieldInput(_$('#c_s1_cellphone'), 'submitContactDetails', 'personalForm', 'c_s1_cellphone');
                    this.userName = _$('#personalForm').find('#c_s1_firstName').val().toString();
                    this.userSurname = _$('#personalForm').find('#c_s1_lastName').val().toString();
                    this.userPhone = _$('#personalForm').find('#c_s1_cellphone').val().toString();
                    this.userEmail = _$('#personalForm').find('#c_s1_email').val().toString()
                }else{
                    _$('#c_s1_email').val(Window.cart.email);
                }
            }
        }else if(Window.typeOfService == "HOME_DELIVERY"){
            if(Window.user){
                _$('#c_s1_email').val(Window.user.email ? Window.user.email : '');
            }else{
                _$('#c_s1_email').val(Window.cart && Window.cart.email ? Window.cart.email : '');
            }
        }
        
    }

    /* Controlla gli step del checkout per abilitare o meno la CTA per completare il checkout */
    checkSalesConditionsError() {
        if(_$('#checkout-sales-conditions').find('input').is(':checked')){
            _$('#checkout-sales-conditions').find('.checkmark').removeClass('error-required-checkout');
            _$('#checkout-sales-conditions-message-error').addClass('uk-hidden');
        }
    }

    //Controllo se provenendo da edit dell'ordine
    initEditMode() {
        if (Window.cart?.parentOrder) {
            _$('.menu-pagamento').addClass('uk-hidden');
            if(Window.cart.paymentMode){
                if(Window.cart.paymentMode == 'PAYMENT_ONLINE'){
                    this.menuPagamento(_$('.menu-pagamento .paga-online'));
                }else{
                    this.menuPagamento(_$('.menu-pagamento .paga-in-negozio'));
                }
            }

            /* Nasconde lo stepper */
            if(!_$('#main-content-body').find('.component-alert-primary').length){
                _$('.page-CheckoutNew').addClass('skip-preauth');
            }

            let urlParams = new URLSearchParams(window.location.search);
            if(urlParams != null && urlParams.has('oldTimeSlotId')){
                this.editStepDays();
                if(_$('.band-undo .parent-order-n').length){
                    _$('.band-undo .parent-order-n').html(Window.cart.parentOrder);
                }
            }
        }
    }

    /**
    * Sets value
    * @param event
    * @param type
    */
    setValueFieldForm(event, type) {
        this[type] = event.value;
    }

        /**
     * Checks input value on keyup
     * @param element
     * @param type
     */
    /**
     * Checks input value on keyup
     * @param element => input
     * @param submitButtonId => cta submit
     * @param formId => id del form
     * @param type => nkInput/id/name dell'input
     */
    controlValueFieldInput(element, submitButtonId: string, formId: string, type?: string) {
        this._validationUtils.newValidInput(element);
        let input = _$(element);

        if (['c_s1_floor', 'c_add_delivery_modal_floor'].indexOf(input.attr('name')) > -1) {
            
            if (!!input.val()) {
                input.attr('data-valid', 'true');
                input.siblings('.message-error').addClass('uk-hidden');
                input.removeClass('uk-form-danger');
            } else {
                if (input.prop('required')) {
                    input.attr('data-valid','false');
                    input.siblings('.message-error').text('Campo obbligatorio.');
                    input.siblings('.message-error').removeClass('uk-hidden');
                    input.addClass('uk-form-danger');
                    input.parent().parent().css('margin-bottom','27px');
                }
            }
        }

        if (['c_s1_lift', 'c_add_delivery_modal_lift'].indexOf(input.attr('name')) > -1) {
            let radioButtons = _$('#'+ formId).find(`.uk-radio[name=${input.attr('name')}]`);
            if (_$('#'+ formId).find(`.uk-radio[name=${input.attr('name')}]:checked`).length) {
                radioButtons.attr('data-valid','true');
                radioButtons.closest('.form-ascensore').find('.message-error').addClass('uk-hidden');
                radioButtons.removeClass('error-required-checkout');
                radioButtons.parent().css('margin-bottom','18px');
            } else {
                radioButtons.attr('data-valid','false');
                radioButtons.closest('.form-ascensore').find('.message-error').text('Campo obbligatorio.');
                radioButtons.closest('.form-ascensore').find('.message-error').removeClass('uk-hidden');
                radioButtons.addClass('error-required-checkout');
                radioButtons.parent().css('margin-bottom','27px');
            }
        }
    }

    checkAllMandatoryFields() {
        _$('[nkButton="checkPromoCode"]')[0].setAttribute('disabled', 'true');
        var cont;
        let requiredFields: any = [];
        requiredFields = _$('[prop="required"]');
        requiredFields.array.forEach(element => {
            cont = this.checkElement(element);
            if (cont) {
                return;
            }
        });

    }

    checkElement(element) {
        if (!!element.val()) {
            return false;
        } else {
            return true;
        }
    }

    menuPagamento(choice) {
        var s2PO = _$('#step-2-paga-online');
        var s2PN = _$('#step-2-paga-negozio');

        if (_$(choice).hasClass('paga-online')) {
            s2PO.removeClass("uk-hidden").addClass('selected');
            s2PN.addClass("uk-hidden").removeClass('selected');
            _$(choice).addClass('selected');
            _$('.menu-pagamento .paga-in-negozio').removeClass('selected');
            this.sendAnalytics('clickPagaOnline', 'Paga online');
        } else {
            s2PO.addClass("uk-hidden").removeClass('selected');
            s2PN.removeClass("uk-hidden").addClass('selected');
            _$(choice).addClass('selected');
            _$('.menu-pagamento .paga-online').removeClass('selected');
            this.sendAnalytics('clickPagaInNegozio', 'Paga in negozio');
        }
    }

    resizeListener(event?) {
        let wdth = innerWidth
        console.log('resize listener')
        var cpn = document.getElementById("box-dati-coupon");
        if (cpn != null && cpn !== undefined) {
            var tgglcpn = document.getElementById("toggleCoupon");
            var ccrdcntnt = document.getElementById("accordionContentCoupon");
            if (wdth < 1024) {
                var parnt = document.getElementById("box-dati-personali");
                tgglcpn.classList.add("uk-accordion-title", "down-arrow");
                ccrdcntnt.classList.add("uk-accordion-content");
            } else {
                var parnt = document.getElementById("box-totale-ordine");
                tgglcpn.classList.remove("uk-accordion-title", "down-arrow");
                ccrdcntnt.classList.remove("uk-accordion-content");
                ccrdcntnt.removeAttribute("hidden");
            }

            parnt.parentNode.insertBefore(cpn, parnt);
        }
    }

    /**
     * Controlla il disabled del submit in base alla modal di provenienza
     * @param type
     * @param e
     */
    changeRadioInput(type: string, defaultAddress?: boolean) {
        //modal indirizzo di consegna
        if (type == 'address') {
            if(defaultAddress){
                _$('#updateAddressDelivery').prop('disabled', true);
            }else{
                _$('#updateAddressDelivery').prop('disabled', false);
            }
            //moda indirizzo di fatturazione
        } else if (type == 'billing') {
            _$('#updateBillingAddress').prop('disabled', false);
        }
    }

    /**
     * Apro la modale di fatturazione in base al parametro (list/new/edit)
     * @param mode ('list' / 'new' / 'edit')
     * @param element elemento cliccato
     */
    async openModalFatturazioneElettronica(mode: string, element?) {
        this._modeFormBilling = mode;

        if (mode == 'list') {
            _Uikit.offcanvas('#offcanvasFatturazioneElettronicaList').show();
            if(this._billingAddressSelected && this._billingAddressSelected.id){
                this.setFatturazioneElettronicaRadio(this._billingAddressSelected.id);
            }

        } else if (mode == 'new') {
            if (Window.user) {
                this.modalFatturazioneElettronica.clearInputValue();
            } else if (this._billingAddressSelected) {
                this.modalFatturazioneElettronica.clearInputValue();
                this.modalFatturazioneElettronica.populateModalEdit(this._billingAddressSelected);
            }
            _Uikit.offcanvas('#offcanvasFatturazioneElettronica').show();
        } else if (mode == 'edit') {
            let dataValue = _$(element).parent().find('input').attr('data-value');
            let data = JSON.parse(decodeURIComponent(dataValue));
            this._billingAddressSelectedEdit = data;
            _Uikit.offcanvas('#offcanvasFatturazioneElettronica').show();
            this.modalFatturazioneElettronica.populateModalEdit(data);
        } else if (mode == 'edit-guest') {
            /* edit per l'utente guest */
            this._modeFormBilling = 'new';
            /* this.modalFatturazioneElettronica.clearInputValue(); */
            this.modalFatturazioneElettronica.populateModalEdit(this._billingAddressSelected);
            _Uikit.offcanvas('#offcanvasFatturazioneElettronica').show();
        }
    }

    /**
     * controllo checkbox fatturazione elettronica in step pagamento
     */
    async changeEnvoiceDetail() {
        if (_$('#checkout_envoice_input').is(':checked')) {
            if (Window.user) {
                await this.getUserBillingAddress();
            } else {
                await this.openModalFatturazioneElettronica('new');
            }
        } else {
            await this.deleteBillingAddressCart();
        }
        this.sendAnalytics('flagFattura', null);
    }

    /**
     * Recupero la lista degli indirizzi di fatturazione
     * @param mode
     */

    getUserBillingAddress(mode?: string): Promise<void> {
        return new Promise((resolve) => {
            if (Window.user) {

                //reset dynamic content
                _$('.list-billing-address-user').html('');
                if (!mode && mode !== 'edit') {
                    _$('.envoice-data-selected').html('');
                }

                let success = async (res) => {
                    if(mode != null && mode == 'loadcheckout'){
                        if(Window.cart && Window.cart.billingDetails && Window.cart.billingDetails.billingAddress){
                            let cartBillingId = Window.cart.billingDetails.billingAddress.id ? Window.cart.billingDetails.billingAddress.id : '';
                            if(cartBillingId != ''){
                                if(res.data && res.data.length > 0){
                                    res.data.forEach((value, index) => {
                                        if(value.id == cartBillingId){
                                            let selectedbillingAddressUserHtml = this.populateSelectedBillingAddressUser(res.data, index);
                                            _$('.envoice-data-selected').append(selectedbillingAddressUserHtml);
                                            _$('#checkout_envoice_input').prop('checked', true);
                                            _$('.envoice-detail-container').fadeIn();
                                        }
                                    })
                                }
                            }
                        }
                    }else if (!mode && mode !== 'edit') {
                        //controllo se ci sono indirizzi salvati, altrimenti faccio lo show del form in pagina
                        _$('#checkout_envoice_input').prop('disabled', false);
                        if (res.data && res.data.length > 0) {
                            this._listBillingAddress = res.data;
                            for (let i = 0; i < res.data.length; i++) {
                                let listBillingAddress: AddressUser = res.data[i];
                                let listBillingAddressUserHtml = this.populateListBillingAddressUser(listBillingAddress, i);
                                _$('.list-billing-address-user').append(listBillingAddressUserHtml);

                                //popolo l'indirizzo di fatturazione di default sullo step3
                                let selectedbillingAddressUserHtml = this.populateSelectedBillingAddressUser(listBillingAddress, i);
                                _$('.envoice-data-selected').append(selectedbillingAddressUserHtml);
                                this.setBillingAddressCart();
                            }

                            _$('#checkout_envoice_input').prop('checked', true);
                            _$('.envoice-detail-container').fadeIn();
                        } else {
                            await this.openModalFatturazioneElettronica('new');
                        }
                    } else {
                        if (res.data && res.data.length > 0) {
                            for (let i = 0; i < res.data.length; i++) {
                                let listBillingAddress: AddressUser = res.data[i];
                                let listBillingAddressUserHtml = this.populateListBillingAddressUser(listBillingAddress, i);
                                _$('.list-billing-address-user').append(listBillingAddressUserHtml);
                            }
                        } else {
                            await this.openModalFatturazioneElettronica('new');
                        }
                    }
                    resolve();
                };
                let error = () => {
                    _$('#checkout_envoice_input').prop('disabled', false);
                    _$('#checkout_envoice_input').prop('checked', false);
                    _$('.envoice-detail-container').fadeOut();
                    resolve();
                };
                this.userService.getUserBillingAddress(success, error);
            }else{
                if(Window.cart && Window.cart.billingDetails && Window.cart.billingDetails.billingAddress){
                    this._billingAddressSelected = Window.cart.billingDetails.billingAddress;
                    _$('.envoice-data-selected').html('');
                    _Uikit.offcanvas('#offcanvasFatturazioneElettronica').hide();
                    let selectedbillingAddressUserHtml = this.populateGuestSelectedBillingAddressUser(this._billingAddressSelected);
                    _$('.envoice-data-selected').append(selectedbillingAddressUserHtml);
                    _$('#checkout_envoice_input').prop('checked', true);
                    _$('.envoice-detail-container').fadeIn();
                }
            }
        });
    }

    /**
     * Popolo la lista degli indirizzi di fatturazione dell'utente
     * @param listAddress
     * @param i
     */
    populateListBillingAddressUser(listBillingAddress, i) {
        let defaultAddress = listBillingAddress.defaultBillingAddress ? `<span class='uk-badge uk-margin-small-left'>Predefinito</span>` : ``;
        let valueData = encodeURIComponent(JSON.stringify(listBillingAddress));
        let input = listBillingAddress.defaultBillingAddress ? `<input class='uk-radio' onchange="CheckoutNew.changeRadioInput('billing', false)" type='radio' name='inputBillingAddressUser' id='inputBillingAddressUser${i}' value='${i}' data-value='${valueData}' checked>` : `<input class='uk-radio' onchange="CheckoutNew.changeRadioInput('billing', false)" type='radio' name='inputBillingAddressUser' id='inputBillingAddressUser${i}' value='${i}' data-value='${valueData}'>`;
        let nickname = listBillingAddress.nickname ? `<span>${listBillingAddress.nickname}</span>` : ``;
        let address = listBillingAddress.line1 ? `<div class='line1'>${listBillingAddress.line1} ${listBillingAddress.postalCode}, ${listBillingAddress.town} ${listBillingAddress.postalCode}</div>` : ``;
        let note = listBillingAddress.summary ? `<div class='note'>${listBillingAddress.summary}</div>` : ``;
        return `<li>
                    <label for='inputAddressUser${i}'>
                        <div class='name'>
                            ${input}
                            ${nickname}
                            ${defaultAddress}
                        </div>
                        <div class='address'>
                            ${address}
                            ${note}
                        </div>
                    </label>
                    <a href='javascript:;' onclick="CheckoutNew.openModalFatturazioneElettronica('edit', this)" class='edit uk-position-bottom-right'>Modifica</a>
                </li>`;
    }

    populateSelectedBillingAddressUser(listBillingAddress: AddressUser, i) {
        if (listBillingAddress.defaultBillingAddress) {
            this._billingAddressSelected = listBillingAddress;
            let typeUser;
            if (listBillingAddress.type === 'azienda') {
                typeUser = `<div><b>${listBillingAddress.businessName}</b> | <b>${listBillingAddress.vat}</b></div>`;
            } else if (listBillingAddress.type === 'persfis' || listBillingAddress.type === 'libprof') {
                typeUser = `<div><b>${listBillingAddress.firstName} ${listBillingAddress.lastName}</b> | <b>${listBillingAddress.fiscalCode}</b></div>`;
            }

            if (listBillingAddress.type === 'azienda' || listBillingAddress.type === 'libprof') {
                this.localStorage.setItem('tipoIntest',JSON.stringify("Azienda / Libero professionista / Onlus"));
            } else {
                this.localStorage.setItem('tipoIntest',JSON.stringify("Privato (non titolare di partita IVA)"));
            }
            this.localStorage.setItem('indirizzoPref',JSON.stringify("SI"));

            return `${typeUser}
                <div>${listBillingAddress.formattedAddress}</div>
                <div><a href='javascript:;' onclick="CheckoutNew.openModalFatturazioneElettronica('list'); CheckoutNew.sendAnalytics('modificaMetodoPagamento')"" class='edit'>Modifica</a></div>`;
        }
    }

    setFatturazioneElettronicaRadio(selectedBillingId){
        let billingAddresses =_$('.list-billing-address-user .uk-radio');
        billingAddresses.each((value, index) => {
            let id = JSON.parse(decodeURIComponent(_$(billingAddresses[value]).attr('data-value'))).id;
            if(selectedBillingId == id){
                _$(billingAddresses[value]).prop('checked', true);
            }
        });
    }

    /**
     * Controllo all'init della pagina gli indirizzi dell'utente, e se combacia uno degli indirizzi associato a quello proveniente da flusso entry
     */
    async checkInitUserAddress() {
        if (Window.typeOfService === 'HOME_DELIVERY') {
            if (Window.user) {
                this.utils.showLoader('', true);
                //se l'utente è loggato
                await this.getUserAddress('init');
                this.utils.showLoader('', false);
            } else {
                if(Window.cart && Window.cart.deliveryAddress && Window.cart.deliveryAddress.firstName){
                    this._deliveryForm = {};
                    this._deliveryForm['formattedAddress'] = Window.cart.deliveryAddress.formattedAddress ? Window.cart.deliveryAddress.formattedAddress : '';
                    this._deliveryForm['cellphone'] = Window.cart.deliveryAddress.cellphone ? Window.cart.deliveryAddress.cellphone : '';
                    this._deliveryForm['firstName'] = Window.cart.deliveryAddress.firstName ? Window.cart.deliveryAddress.firstName : '';
                    this._deliveryForm['lastName'] = Window.cart.deliveryAddress.lastName ? Window.cart.deliveryAddress.lastName : '';
                    this._deliveryForm['floor'] = (Window.cart.deliveryAddress.floor || Window.cart.deliveryAddress.floor === 0) ? Window.cart.deliveryAddress.floor : '';
                    this._deliveryForm['doorbell'] = Window.cart.deliveryAddress.doorbell ? Window.cart.deliveryAddress.doorbell : '';
                    this._deliveryForm['lift'] = Window.cart.deliveryAddress.lift ? Window.cart.deliveryAddress.lift : '';
                    this._deliveryForm['country'] = {
                        'isocode': Window.cart.deliveryAddress.country ? Window.cart.deliveryAddress.country.isocode : ''
                    };
                    this._deliveryForm['defaultAddress'] = Window.cart.deliveryAddress.defaultAddress ? Window.cart.deliveryAddress.defaultAddress : '';
                    this._deliveryForm['line1'] = Window.cart.deliveryAddress.line1 ? Window.cart.deliveryAddress.line1 : '';
                    this._deliveryForm['line2'] = Window.cart.deliveryAddress.line2 ? Window.cart.deliveryAddress.line2 : '';
                    this._deliveryForm['postalCode'] = Window.cart.deliveryAddress.postalCode ? Window.cart.deliveryAddress.postalCode : '';
                    this._deliveryForm['town'] = Window.cart.deliveryAddress.town ? Window.cart.deliveryAddress.town : '';
                    this._deliveryForm['nickname'] = Window.cart.deliveryAddress.nickname ? Window.cart.deliveryAddress.nickname : '';
                    this._deliveryForm['latitude'] = Window.cart.deliveryAddress.latitude ? Window.cart.deliveryAddress.latitude : '';
                    this._deliveryForm['longitude'] = Window.cart.deliveryAddress.longitude ? Window.cart.deliveryAddress.longitude : '';
                    this._deliveryForm['reception'] = Window.cart.deliveryAddress.reception ? Window.cart.deliveryAddress.reception : '';
                    this._deliveryForm['building'] = Window.cart.deliveryAddress.building ? Window.cart.deliveryAddress.building : '';
                    this._deliveryForm['remarks'] = Window.cart.deliveryAddress.remarks ? Window.cart.deliveryAddress.remarks : '';
                    this._deliveryForm['notCompleted'] = Window.cart.deliveryAddress.notCompleted ? true : false;
                    this._deliveryForm['cellphoneVerified'] = true;
                    this.fillAddressForm();
                }
            }
        }
    }

    /**
     * precompila il form di un utente guest
     */
    fillAddressForm(){
        let deliveryAddress;
        if(!Window.user){
            deliveryAddress = this._deliveryForm;
        }else{
            deliveryAddress = Window.cart.deliveryAddress;
        }
        _$('#personalForm').find('#c_s1_cellphone').val(deliveryAddress.cellphone ? deliveryAddress.cellphone : '');
        _$('#personalForm').find('#c_s1_firstName').val(deliveryAddress.firstName ? deliveryAddress.firstName : '');
        _$('#personalForm').find('#c_s1_lastName').val(deliveryAddress.lastName ? deliveryAddress.lastName : '');
        (deliveryAddress.floor || deliveryAddress.floor === 0) ? _$('#addressForm').find('#c_s1_floor').val(deliveryAddress.floor) : console.log('no floor selected');
        _$('#addressForm').find('#c_s1_doorbell').val(deliveryAddress.doorbell ? deliveryAddress.doorbell : '');
        _$('#addressForm').find('#c_s1_building').val(deliveryAddress.reception ? deliveryAddress.reception : '');
        _$('#addressForm').find('#c_s1_remarks').val(deliveryAddress.remarks ? deliveryAddress.remarks : '');
        _$('#addressForm').find('#c_s1_building').val(deliveryAddress.building ? deliveryAddress.building : '');
        deliveryAddress.lift ? _$('#addressForm').find('#checkout-ascensore').trigger('click') : '';
        deliveryAddress.reception ? _$('#addressForm').find('#checkout-portineria').trigger('click') : '';

        if(Window.user && deliveryAddress && deliveryAddress.nickname){
            _$('#addressForm').find('#c_s1_nickname').val(deliveryAddress.nickname);
            this.controlValueFieldInput(_$('#addressForm').find('#c_s1_nickname'), 'submitDeliveryAddress', 'addressForm', 'c_s1_nickname');
        }

        this.controlValueFieldInput(_$('#personalForm').find('#c_s1_firstName'), 'submitDeliveryAddress', 'personalForm', 'c_s1_firstName');
        this.controlValueFieldInput(_$('#personalForm').find('#c_s1_cellphone'), 'submitDeliveryAddress', 'personalForm', 'c_s1_cellphone');
        this.controlValueFieldInput(_$('#personalForm').find('#c_s1_lastName'), 'submitDeliveryAddress', 'personalForm', 'c_s1_lastName');
        this.controlValueFieldInput(_$('#addressForm').find('#c_s1_remarks'), 'submitDeliveryAddress', 'addressForm', 'c_s1_remarks');
        this.controlValueFieldInput(_$('#addressForm').find('#c_s1_doorbell'), 'submitDeliveryAddress', 'addressForm', 'c_s1_doorbell');
        this.utils.checkFormValidator('submitDeliveryAddress', 'addressForm');
        this.utils.checkFormValidator('submitDeliveryAddress', 'personalForm');
    }

    /**
     * Carico la lista degli indirizzi dell'utente
     */
    getUserAddress(type?: string): Promise<void> {
        return new Promise((resolve) => {
            if (Window.user && Window.typeOfService === 'HOME_DELIVERY') {
                let success = async (res) => {
                    //controllo se ci sono indirizzi salvati, altrimenti faccio lo show del form in pagina
                    if (res.data && res.data.length > 0) {
                        this.bookUtils._deliveryAddressesCount = res.data.length;
                        _$('#offcanvasCheckoutAddressDeliveryList .list-address-user').html('');
                        _$('#offcanvasCheckoutAddressDeliveryList').find('#updateAddressDelivery').prop('disabled', true);
                        for (let i = 0; i < res.data.length; i++) {
                            let selectedAddress = false;
                            let listAddress: AddressUser = res.data[i];
                            if (this.selectedMyconadAddressId && listAddress.id && listAddress.id == this.selectedMyconadAddressId) {
                                selectedAddress = true;
                            }
                            let listAddressUserHtml = this.populateListAddressUser(listAddress, i, selectedAddress);
                            this.localStorage.setItem('listAddressUser', JSON.stringify(listAddress));
                            _$('#offcanvasCheckoutAddressDeliveryList .list-address-user').append(listAddressUserHtml);
                            if (this.selectedMyconadAddressId && listAddress.id && listAddress.id == this.selectedMyconadAddressId) {
                                _$('#inputAddressUser' + i).prop('checked', true);
                            }

                        }
                        if (type === 'modal') {
                            _Uikit.offcanvas('#offcanvasCheckoutAddressDeliveryList').show();
                        } else if (type === 'init') {
                            _$('#addressForm .opzioni-modifica-indirizzo').find('.separator, .scegli-indirizzo').css('display', 'block');
                            //faccio il check tra lat e lng proveninte da entrypage con lat e lng di un indirizzo della lista dell'utente (indirizzoCorrispondente)
                            this._myconadAddresses = res.data;
                            if (this.checkEqualityLatLng(res.data)) {
                                let addressFound = JSON.parse(JSON.stringify(this.checkEqualityLatLng(res.data)));
                                //gli indirizzi combaciano
                                _$('#addressForm').find('.negozio-consegna span').html(this._addressDeliveryFormattedAddress);
                                this._deliveryForm = {};
                                this._deliveryForm['formattedAddress'] = addressFound.formattedAddress;
                                this._deliveryForm['cellphone'] = addressFound.cellphone;
                                this._deliveryForm['firstName'] = addressFound.firstName;
                                this._deliveryForm['lastName'] = addressFound.lastName;
                                this._deliveryForm['floor'] = addressFound.floor;
                                this._deliveryForm['doorbell'] = addressFound.doorbell;
                                this._deliveryForm['lift'] = addressFound.lift;
                                this._deliveryForm['country'] = {
                                    'isocode': addressFound.country.isocode
                                };
                                this._deliveryForm['defaultAddress'] = addressFound.defaultAddress;
                                this._deliveryForm['line1'] = addressFound.line1;
                                this._deliveryForm['line2'] = addressFound.line2;
                                this._deliveryForm['postalCode'] = addressFound.postalCode;
                                this._deliveryForm['town'] = addressFound.town;
                                this._deliveryForm['nickname'] = addressFound.nickname;
                                this._deliveryForm['latitude'] = addressFound.latitude;
                                this._deliveryForm['longitude'] = addressFound.longitude;
                                /* Campi non obbligatori */
                                this._deliveryForm['reception'] = addressFound.reception ? addressFound.reception : '';
                                this._deliveryForm['building'] = addressFound.building ? addressFound.building : '';
                                this._deliveryForm['remarks'] = addressFound.remarks ? addressFound.remarks : '';
                                this._deliveryForm['notCompleted'] = addressFound.notCompleted ? true : false;
                                this._deliveryForm['cellphoneVerified'] = true;
                                this._deliveryForm['id'] = addressFound.id ? addressFound.id : '';
                                if(addressFound.id){
                                    this.selectedMyconadAddressId = addressFound.id;
                                }
                                this._dataUserAddressPreview = addressFound.summary + ' | ' + this.userEmail;
                                await this.setAddressCart(this._deliveryForm);
                                this.fillAddressFromMyconad(this._deliveryForm);
                            }
                        }
                    }
                    resolve();
                };
                let error = (err) => {
                    this.bookUtils._deliveryAddressesCount = 0;
                    console.log('getUserAddress err: ', err);
                    resolve();
                };
                this.userService.getUserAddress(success, error);
            }
        });
    }

    fillAddressFromMyconad(deliveryForm){
        _$('#addressForm').find('#c_s1_nickname').val(deliveryForm.nickname ? deliveryForm.nickname : '');
        _$('#personalForm').find('#c_s1_cellphone').val(deliveryForm.cellphone ? deliveryForm.cellphone : '');
        _$('#personalForm').find('#c_s1_firstName').val(deliveryForm.firstName ? deliveryForm.firstName : '');
        _$('#personalForm').find('#c_s1_lastName').val(deliveryForm.lastName ? deliveryForm.lastName : '');
        (deliveryForm.floor || deliveryForm.floor === 0) ? _$('#addressForm').find('#c_s1_floor').val(deliveryForm.floor) : console.log('no floor selected');
        _$('#addressForm').find('#c_s1_doorbell').val(deliveryForm.doorbell ? deliveryForm.doorbell : '');

        _$('#addressForm').find('#c_s1_building').val(deliveryForm.reception ? deliveryForm.reception : '');
        _$('#addressForm').find('#c_s1_remarks').val(deliveryForm.remarks ? deliveryForm.remarks : '');
        _$('#addressForm').find('#c_s1_building').val(deliveryForm.building ? deliveryForm.building : '');
        deliveryForm.lift ? _$('#addressForm').find('#checkout-ascensore').trigger('click') : '';
        deliveryForm.reception ? _$('#addressForm').find('#checkout-portineria').trigger('click') : '';
        
        this.controlValueFieldInput(_$('#addressForm').find('#c_s1_nickname'), 'submitDeliveryAddress', 'addressForm', 'c_s1_nickname');
        this.controlValueFieldInput(_$('#addressForm').find('#c_s1_firstName'), 'submitDeliveryAddress', 'personalForm', 'c_s1_firstName');
        this.controlValueFieldInput(_$('#addressForm').find('#c_s1_cellphone'), 'submitDeliveryAddress', 'personalForm', 'c_s1_cellphone');
        this.controlValueFieldInput(_$('#addressForm').find('#c_s1_lastName'), 'submitDeliveryAddress', 'personalForm', 'c_s1_lastName');
        this.controlValueFieldInput(_$('#addressForm').find('#c_s1_remarks'), 'submitDeliveryAddress', 'addressForm', 'c_s1_remarks');
        this.controlValueFieldInput(_$('#addressForm').find('#c_s1_doorbell'), 'submitDeliveryAddress', 'addressForm', 'c_s1_doorbell');
        this.utils.checkFormValidator('submitDeliveryAddress', 'addressForm');
        this.utils.checkFormValidator('submitDeliveryAddress', 'personalForm');
    }

    /**
     * Imposta l'indirizzo di consegna nel carrello per il ricalcolo dei sovrapprezzi del piano
     * @param deliveryAddress
     */
    async setAddressCart(deliveryForm: object, fromFloorUpdate?: boolean, forCheckout?: boolean): Promise<void>{
        return new Promise((resolve, reject) => {
            this.utils.showLoader('', true);
            let success = (res) => {
                this.utils.showLoader('', false);
                if(!forCheckout){
                    this.getScontrino();
                }
                resolve();
            }

            let error = (err) => {
                this.utils.showLoader('', false);
                console.log('error aggiornamento indirizzo del carrello');
                reject();
            }

            let body;

            if(Window.user && deliveryForm['id'] != null && deliveryForm['id'] != '' && !fromFloorUpdate){
                body = {
                    'addressId': deliveryForm['id']
                }
            }else{
                body = {
                    'address' : deliveryForm
                }
            }

            this.cartService.setAddressCart(success, error, body);
        })
        
    }

    /* update scontrino del checkout */
    getScontrino(){
        let link = _$('.primary-panel').attr('data-update-receipt');
        let success = (res) => {
            let resHTML;
            if(res.length){
                res = res.trim();
                let parser = new DOMParser();
                resHTML = parser.parseFromString(res, 'text/html');
            }

            _$('.scontrino-step-1').replaceWith(_$(resHTML).find('#wrapper-pay').html());
            _$('#box-totale-ordine-2').replaceWith(_$(resHTML).find('#wrapper-pay-mini').html());

            if(this.localStorage.getFromStorage('checkCGV')){
                this.simulateCGVClick();
            }
            
            this.setAnalyticsScontrino();

            this.openWarningAggiungiProdotti();
        }

        let error = (err) => {
            console.error(err);
        }

        this.cartService.getPageUpdate(success, error, link);
    }

    simulateCGVClick(){
        _$('#checkout-sales-conditions input').trigger('click');
    }

    /**
     * Popolo la lista degli indirizzi di spedizione dell'utente (simile alla funzione utils, ma con html diverso)
     * @param listAddress
     * @param i
     */
    populateListAddressUser(listAddress: AddressUser, i, selectedAddress: boolean) {
        let defaultAddress = listAddress.defaultAddress ? `<span class='uk-badge uk-margin-small-left'>Preferito</span>` : ``;
        let input = listAddress.defaultAddress ? `<input class='uk-radio' onchange="CheckoutNew.changeRadioInput('address', ${selectedAddress})" type='radio' name='inputAddressUser' id='inputAddressUser${i}' value='${i}'>` : `<input class='uk-radio' onchange="CheckoutNew.changeRadioInput('address', ${selectedAddress})" type='radio' name='inputAddressUser' id='inputAddressUser${i}' value='${i}'>`;
        let nickname = listAddress.nickname ? `<span>${listAddress.nickname}</span>` : ``;
        let address = listAddress.formattedAddress ? `<div class='line1'>${listAddress.formattedAddress}</div>` : ``;
        let note = listAddress.summary ? `<div class='note'>${listAddress.summary} | ${this.userEmail}</div>` : ``;
        let listAddressURI = encodeURI(JSON.stringify(listAddress));
        return `<li data-address=${listAddressURI}>
                    <label for='inputAddressUser${i}'>
                        <div class='name'>
                            ${input}
                            ${nickname}
                            ${defaultAddress}
                        </div>
                        <div class='address'>
                            ${address}
                            ${note}
                        </div>
                    </label>
                </li>`;
    }

    /**
     * Controllo se lat/lng provenienti
     * @param userAddresses oggetto lista address utente
     */
    checkEqualityLatLng(userAddresses): object {
        let equality: object;
        let summary: string;
        userAddresses.filter((obj) => {
            if (obj.latitude === Window.cart.deliveryAddress.latitude && obj.longitude === Window.cart.deliveryAddress.longitude && !equality) {
                equality = obj;
                this._dataUserAddressPreview = obj.summary;
            }
        });
        return equality;
    }

    /* Applicazione coupon */
    applyCoupon(){
        let timerCoupon = 7000;
        let voucherId = _$('#codePromo') ? _$('#codePromo').val() : '';
        this.clearCouponErrorFeedback();
        this.clearCouponSuccessFeedback();
                                
        let success = (res) => {
            _$('.section-codice-sconto').find('.coupon-applied-list').prepend(`<span href="#" id=${voucherId} class="uk-tag"><span class="voucher-id">${voucherId}</span> <a href="javascript:;" onclick="CheckoutNew.removeCoupon(this)"><i class="icon-close"></i><i class="icon-check"></i></a></span>`);
            _$('.section-codice-sconto').addClass('success');
            this.sendAnalytics('applicaCoupon', voucherId); 
            _$('.coupon-applied-list').addClass('coupon-applied');
            _$('#codePromo').val('');

            if(res.data.data.removedPromo){
                _$('.section-codice-sconto').addClass('ko-removed-promo');
            }

            if(res.data?.data?.removedCouponList && res.data.data.removedCouponList.length > 0){
                const removedCoupons = res.data.data.removedCouponList;
                _$('#coupon-warning-message-coupons').find(".removed-coupons-warning .removed-coupons-list")
                    .text(removedCoupons.join(', ') || '');
                _$('.section-codice-sconto').addClass('ko-removed-coupons');
                removedCoupons.forEach((couponID: string) => {
                    const couponElToRemove = _$('.coupon-applied-list').find(`#${couponID}`)[0] || null;
                    if (couponElToRemove) {
                        couponElToRemove.remove();
                    } else {
                        console.warn(`Coupon element '${couponID}' to remove non found in the page`);
                    }
                })    
            }

            setTimeout(() => {
                this.clearCouponErrorFeedback();
                _$('#coupon-warning-message-coupons').find(".removed-coupons-warning .removed-coupons-list")
                    .text('');
            }, timerCoupon)

            if (Window.cart?.parentOrder) {
                window.location.href = Window.urlSite.checkoutRecognized;
            } else {
                this.utils.showLoader('', false);
                this.getScontrino();
                setTimeout(() => {
                    this.clearCouponSuccessFeedback();
                }, timerCoupon);
            }
        }

        let error = (err) => {
            this.utils.showLoader('', false);
            if(err.response && err.response.data && err.response.data.message){
                console.error('logging coupon error: ' + err.response.data.message)
                switch(err.response.data.message){
                    case 'NOT_SATISFIED':
                        _$('.section-codice-sconto').addClass('ko-conditions');
                        break;
                    case 'EXPIRED':
                        _$('.section-codice-sconto').addClass('ko-expired');
                        break;
                    case 'ERROR':
                    default:
                        _$('.section-codice-sconto').addClass('ko-other');
                        break;
                }

                this.sendAnalytics('alertCoupon', err.response.data.message);
            }else{
                _$('.section-codice-sconto').addClass('ko-other');

                this.sendAnalytics('alertCoupon', 'OTHER');
            }

            setTimeout(() => {
                this.clearCouponErrorFeedback();
            }, timerCoupon)
            
            setTimeout(() => {
                this.sendAnalytics('erroreFormApplicaCoupon',null);//4.3.35
            }, 1000);
            
        }

        let body = {
            'voucherId': voucherId,
            'action': 'ADD'
        }

        if(Window.user){
            if(Window.user.cartaFedelta){
                body['cartaFedelta'] = Window.user.cartaFedelta;
            }
        }
        
        this.utils.showLoader('', true);
        this.cartService.manageCoupon(success, error, body);
    }

    disableCouponInput(){
        _$('#codePromo').prop('disabled', true);
        _$('button[nkButton=checkPromoCode]').prop('disabled', true);
    }

    enableCouponInput(){
        _$('#codePromo').prop('disabled', false);
        _$('button[nkButton=checkPromoCode]').prop('disabled', false);
    }

    removeCoupon(coupon: object){
        let voucherId = _$(coupon).parent().find('.voucher-id').html();

        let success = (res) => {
            _$(coupon).parent().remove();
            this.sendAnalytics('rimuoviCoupon', voucherId);//4.3.33

            if(Window.cart?.parentOrder){
                window.location.href = Window.urlSite.checkoutRecognized;
            }else{
                this.utils.showLoader('', false);
                this.getScontrino();
            }
        }

        let error = (err) => {
            this.utils.showLoader('', false);
            console.log(err);
        }

        let body = {
            'voucherId': voucherId,
            'action': 'REMOVE'
        }
        
        this.utils.showLoader('', true);
        this.cartService.manageCoupon(success, error, body);
    }

    clearCouponSuccessFeedback(){
        _$('.section-codice-sconto').removeClass('success');
    }

    clearCouponErrorFeedback(){
        _$('.section-codice-sconto').removeClass('ko-conditions ko-expired ko-single-coupon ko-other ko-removed-promo ko-removed-coupons');
    }

    /**
     * Click edit su step 2. Giorno e orario
     */
    editStepDays() {
        let preselectTimeslot = true;
        this.bookUtils.loadStep2checkout(Window.typeOfService == 'HOME_DELIVERY' ? 'ricevi' : 'ritira', preselectTimeslot);
        let originalOnclick = _$('#modal-timeslot .js-button-conferma-timeslot').attr('onclick');
        _$('#modal-timeslot .js-button-conferma-timeslot').attr('onclick', 'CheckoutNew.bookingSlot()');
        _$('#modal-timeslot').on('hide', () => {
            _$('#modal-timeslot .js-button-conferma-timeslot').attr('onclick', originalOnclick);
        });
    }

    async bookingSlot() {
        try {
            await this.bookUtils.bookingSlotCheckout();
            this.sendAnalytics('prenotaSlot');
            if(Window.cart?.parentOrder){
                window.location.href = Window.urlSite.checkoutRecognized;
            } else {
                if (_$('.coupon-applied-list').length && _$('.coupon-applied-list').find(".voucher-id").length)
                    window.location.reload();
                else
                    this.getScontrino();
            }
        } catch {
            console.log('errore booking slot checkout');
        }
    }

    goToStepPayment(){
        _$('body').addClass('step-2');
        _$('.feedback-full').attr('uk-sticky', 'offset: 165;bottom: .main-content;media: @xl');
        _$('body, html').scrollTop(0);
        this.sendAnalytics('pageview-step2');
    }

    /**
     * il booleano deleteTransaction indica di chiamare la servlet per cancellare la transazione conad card in corso
     */
    backToStepData(deleteTransaction: boolean = false){
        _$('body').removeClass('step-2');
        _$('.feedback-full').attr('uk-sticky', 'offset: 130;bottom: .main-content;media: @xl');
        _$('body, html').scrollTop(0);
        this.sendAnalytics('pageview');
        if(deleteTransaction){
            let success = (res) => {
                console.log(res);
            }

            let error = (err) => {
                console.error(err);
            }

            this.cartService.deleteTransaction(success, error);
        }
    }

    /**
     * Applica nuovo indirizzo dalla lista indirizzo dell'utente
     */
    updateAddressDelivery() {
        this.bookUtils.checkoutAddingNewAddress = false;
        _Uikit.modal('#modalModificaIndirizzoSceltaIndirizzo').show();
    }

    /**
     * Salva Modal Fatturazione Elettronica
     */
    async submitEnvoiceData() {
        if(this.validBillingForm()){
            
            let values = {};
            let fields = _$('#envoiceForm').find('input[data-valid="true"], select[data-valid="true"], input[type="checkbox"]');
            let type = _$('#envoiceSelection').val();
            let id = this._billingAddressSelectedEdit ? this._billingAddressSelectedEdit.id : '';
            fields.each(function () {
                if (_$('#f_defaultBillingAddress').prop('checked')) {
                    _$('#f_defaultBillingAddress').val('on');
                } else {
                    _$('#f_defaultBillingAddress').val('off');
                }
                let input = _$(this), name = input.attr('name'), value = input.val();
                values[name] = value;
            });
            if (this._modeFormBilling === 'new') {
                if (this._billingAddressSelectedEdit) {
                    this._billingAddressSelectedEdit.id = '';
                }
                await this.addEditBillingAddress(id, values, type, 'add');
            } else if (this._modeFormBilling === 'edit') {
                await this.addEditBillingAddress(id, values, type, 'edit');
            }
            this.sendAnalytics('salvaDatiFattura', null);
        }
    }

    validBillingForm() {
        let $form = _$('#offcanvasFatturazioneElettronica').find('form');
        let $allInputs = $form.find('input, select');
        let validInputs = [];

        _$($allInputs).each((index, singleInput) => {
            let $input = _$(singleInput);
            this.modalFatturazioneElettronica.controlValueFieldInput($input);
            if ($input.attr('data-valid') == 'false') {
                validInputs.push(false);
            } else {
                validInputs.push(true);
            }
        });
        return validInputs.indexOf(false) < 0;
    }

    /**
     * Aggiorna l'indirizzo seleziona dalla lista
     */
    async updateBillingAddress() {
        let inputs = _$('.list-billing-address-user').find('input');

        _$(inputs).each(() => {
            if (_$(this).prop('checked')) {
                let dataValue = _$(this).attr('data-value');
                let data = JSON.parse(decodeURIComponent(dataValue));
                _$('.envoice-data-selected').html('');

                this._billingAddressSelected = data;
            }
        });

        await this.setBillingAddressCart();
        let typeUser;
        if (this._billingAddressSelected.type === 'azienda') {
            typeUser = `<div><b>${this._billingAddressSelected.businessName}</b> | <b>${this._billingAddressSelected.vat}</b></div>`;
        } else if (this._billingAddressSelected.type === 'persfis' || this._billingAddressSelected.type === 'libprof') {
            typeUser = `<div><b>${this._billingAddressSelected.firstName} ${this._billingAddressSelected.lastName}</b> | <b>${this._billingAddressSelected.fiscalCode}</b></div>`;
        }
        let htmlData = `${typeUser}
                <div>${this._billingAddressSelected.formattedAddress}</div>
                <div><a href='javascript:;' onclick="CheckoutNew.openModalFatturazioneElettronica('list'); CheckoutNew.sendAnalytics('modificaMetodoPagamento')"" class='edit'>Modifica</a></div>`;
        _$('.envoice-data-selected').append(htmlData);

        _Uikit.offcanvas('#offcanvasFatturazioneElettronicaList').hide();
    }

    /**
     * Aggiungi/Modifica Indirizzo di Fatturazione
     * @param id
     * @param data
     * @param type
     * @param mode ('add' / 'edit')
     */
    addEditBillingAddress(id?: string, data?: any, type?: any, mode?: string): Promise<void> {
        
        return new Promise(async (resolve) => {
            this.utils.showLoader(_$('#submitEnvoiceData'), true, LOADER_POSITION.BUTTON);
            let success = async (res) => {
                await this.getUserBillingAddress();
                if (res.data) {
                    this._billingAddressSelected = body;
                } else {
                    console.log('no res');
                }
                
                this.utils.showLoader(_$('#submitEnvoiceData'), false, LOADER_POSITION.BUTTON);
                await this.setBillingAddressCart();
                _Uikit.offcanvas('#offcanvasFatturazioneElettronica').hide();
                this.updateBillingAddressOnAdd(body);
                resolve();
            };
            let error = (error) => {
                this.utils.showLoader(_$('#submitEnvoiceData'), false, LOADER_POSITION.BUTTON);
                resolve();
            };

            let body;
            switch (type) {
                case 'azienda':
                    body = {
                        'type': 'azienda',
                        'nickname': data.f_etichetta ? data.f_etichetta : '',
                        'firstName': data.f_name ? data.f_name : '',
                        'lastName': data.f_surname ? data.f_surname : '',
                        'businessName': data.f_rsociale ? data.f_rsociale : '',
                        'vat': data.f_piva ? data.f_piva : '',
                        'fiscalCode': data.f_cf ? data.f_cf : '',

                        'line1': data.f_address ? data.f_address : '',
                        'postalCode': data.f_cap ? data.f_cap : '',
                        'district': data.f_provincia ? data.f_provincia : '',
                        'town': data.f_city ? data.f_city : '',
                        'country': {
                            'isocode': data.f_nazione ? data.f_nazione : ''
                        },

                        'pec': data.f_pec ? data.f_pec : '',
                        'sdiCode': data.f_cdestinatario ? data.f_cdestinatario : '',
                        'defaultBillingAddress': data.f_defaultBillingAddress && data.f_defaultBillingAddress === 'on',

                        //optional
                        'email': data.f_mail ? data.f_mail : '',
                        'cellphone': data.f_cell ? data.f_cell : '',
                        'phone': data.f_tel ? data.f_tel : ''
                    };
                    break;

                case 'persfis':
                    body = {
                        'type': 'persfis',
                        'nickname': data.f_etichetta ? data.f_etichetta : '',
                        'firstName': data.f_name ? data.f_name : '',
                        'lastName': data.f_surname ? data.f_surname : '',
                        'fiscalCode': data.f_cf ? data.f_cf : '',

                        'line1': data.f_address ? data.f_address : '',
                        'postalCode': data.f_cap ? data.f_cap : '',
                        'district': data.f_provincia ? data.f_provincia : '',
                        'town': data.f_city ? data.f_city : '',
                        'country': {
                            'isocode': data.f_nazione ? data.f_nazione : 'IT'
                        },

                        'email': data.f_mail ? data.f_mail : '',
                        'pec': data.f_pec ? data.f_pec : '',
                        'sdiCode': data.f_cdestinatario ? data.f_cdestinatario : '',
                        'defaultBillingAddress': data.f_defaultBillingAddress && data.f_defaultBillingAddress === 'on',

                        //optional
                        'cellphone': data.f_cell ? data.f_cell : '',
                        'phone': data.f_tel ? data.f_tel : ''
                    };
                    break;
            }

            body.id = id ? id : '';

            if (mode && mode === 'add') {
                //add new billing Address
                if (Window.user) {
                    this.userService.addUserBillingAddress(success, error, body);
                } else {
                    /* Caso guest */
                    this._billingAddressSelected = body;
                    _$('.envoice-data-selected').html('');
                    this.utils.showLoader(_$('#submitEnvoiceData'), false, LOADER_POSITION.BUTTON);
                    _Uikit.offcanvas('#offcanvasFatturazioneElettronica').hide();
                    let selectedbillingAddressUserHtml = this.populateGuestSelectedBillingAddressUser(this._billingAddressSelected);
                    await this.setBillingAddressCart();
                    _$('.envoice-data-selected').append(selectedbillingAddressUserHtml);
                    _$('#checkout_envoice_input').prop('checked', true);
                    _$('.envoice-detail-container').fadeIn();
                }
            } else if (mode && mode === 'edit') {
                //edit billing address
                this.userService.editUserBillingAddress(success, error, body);
            }
        });
    }

    populateGuestSelectedBillingAddressUser(listBillingAddress: AddressUser) {
        this._billingAddressSelected = listBillingAddress;
        let typeUser;
        if (listBillingAddress.type === 'azienda') {
            typeUser = `<div><b>${listBillingAddress.businessName}</b> | <b>${listBillingAddress.vat}</b></div>`;
        } else if (listBillingAddress.type === 'persfis' || listBillingAddress.type === 'libprof') {
            typeUser = `<div><b>${listBillingAddress.firstName} ${listBillingAddress.lastName}</b> | <b>${listBillingAddress.fiscalCode}</b></div>`;
        }

        return `${typeUser}
            <div>${listBillingAddress.line1}, ${listBillingAddress.postalCode} - ${listBillingAddress.town}</div>
            <div><a href='javascript:;' onclick="CheckoutNew.openModalFatturazioneElettronica('edit-guest')" class='edit'>Modifica</a></div>`;
    }

     /**
     * Aggiorna l'indirizzo di fatturazione a valle di aggiunta
     */
     updateBillingAddressOnAdd(data) {
        _$('.envoice-data-selected').html('');
        let typeUser;
        if (data.type === 'azienda') {
            typeUser = `<div><b>${data.businessName}</b> | <b>${data.vat}</b></div>`;
        } else if (data.type === 'persfis' || data.type === 'libprof') {
            typeUser = `<div><b>${data.firstName} ${data.lastName}</b> | <b>${data.fiscalCode}</b></div>`;
        }
        let htmlData = `${typeUser}
                <div>${data.line1}, ${data.town}, ${data.postalCode}, ${data.district}</div>
                <div><a href='javascript:;' onclick="CheckoutNew.openModalFatturazioneElettronica('list'); CheckoutNew.sendAnalytics('modificaMetodoPagamento')"" class='edit'>Modifica</a></div>`;
        _$('.envoice-data-selected').append(htmlData);

        _Uikit.offcanvas('#offcanvasFatturazioneElettronicaList').hide();
    }

    /**
     * Setta l'indirizzo di fatturazione a carrello
     */
    setBillingAddressCart(): Promise<void>{
        return new Promise ((resolve, reject) => {
            let success = (res) => {
                this.utils.showLoader('', false);
                resolve();
            }
    
            let error = (err) => {
                this.utils.showLoader('', false);
                console.error(err);
                reject();
            }

            let body = {
                'billingAddress': this._billingAddressSelected
            }
    
            this.utils.showLoader('', true);
            this.cartService.setBillingAddressCart(success, error, body);
        });
        
    }

    /**
     * rimuove l'indirizzo di fatturazione a carrello
     */
    deleteBillingAddressCart(): Promise<void>{
        return new Promise ((resolve, reject) => {
            let success = (res) => {
                this.utils.showLoader('', false);
                _$('#checkout_envoice_input').prop('checked', false);
                _$('.envoice-detail-container').fadeOut();
                this._billingAddressSelected = null;
                resolve();
            }
    
            let error = (err) => {
                this.utils.showLoader('', false);
                console.error(err);
                reject();
            }
    
            this.utils.showLoader('', true);
            this.cartService.deleteBillingAddressCart(success, error);
        });
        
    }

    

    /**
     * cambio store modal modifica negozio (#modalModificaNegozio)
     */
    async changeStoreGenericModal() {
        if(this.bookUtils._changePdvAndDeliveryAddress){
            this.getUserAddress('modal'); 
            this.sendAnalytics('ctaProcedi', null);
        }else{
            let stepCheckout = true;

            this.localStorage.setItem('step2Flow', JSON.stringify('modifyStep2'));

            if (Window.typeOfService == 'HOME_DELIVERY') {
                this.localStorage.setItem('selectedAddress_SAP_Format', JSON.stringify(Window.cart.deliveryAddress));
                let stores = await this.google.fetchStores(Window.cart.deliveryAddress, Window.typeOfService, stepCheckout);
                if(stores != null && stores == 'true'){
                    let stopOnPdvList = true;
                    this.google.loadStores(Window.typeOfService, false, stepCheckout, Window.cart.deliveryAddress, stopOnPdvList);
                    if(Window.typeOfService && Window.pointOfService && Window.pointOfService.name){
                        this.onboardingManager.togglePreselectedPdv(Window.typeOfService, Window.pointOfService.name)
                    }
                }else if(stores != null && stores == 'false'){
                    this.onboardingManager.manageModalSteps('step1Checkout', 'HOME_DELIVERY', Window.cart.deliveryAddress);
                }
            } else {
                /* Aprire modal onboarding step2 mappa */
                let selectedAddressSAPFormat;
                if(this.localStorage.getFromStorage('selectedAddressOrderAndCollect') != null){
                    selectedAddressSAPFormat = this.localStorage.getFromStorage('selectedAddressOrderAndCollect');
                }else{
                    selectedAddressSAPFormat = Window.pointOfService.address;
                    if(Window.pointOfService && Window.pointOfService.geoPoint){
                        selectedAddressSAPFormat['latitude'] = Window.pointOfService.geoPoint.latitude;
                        selectedAddressSAPFormat['longitude'] = Window.pointOfService.geoPoint.longitude;
                    }else if(this.localStorage.getFromStorage('selectedAddress_SAP_Format') != null){
                        selectedAddressSAPFormat = this.localStorage.getFromStorage('selectedAddress_SAP_Format');
                    }
                }

                this.localStorage.setItem('selectedAddress_SAP_Format', JSON.stringify(selectedAddressSAPFormat));

                let stores = await this.google.fetchStores(selectedAddressSAPFormat, Window.typeOfService, stepCheckout);
                if(stores != null && stores == 'true'){
                    this.google.loadStores(Window.typeOfService, false, stepCheckout, selectedAddressSAPFormat);
                    if(Window.typeOfService && Window.pointOfService && Window.pointOfService.name){
                        this.onboardingManager.togglePreselectedPdv(Window.typeOfService, Window.pointOfService.name)
                    }
                }else if(stores != null && stores == 'false'){
                    this.onboardingManager.manageModalSteps('step1Checkout', 'ORDER_AND_COLLECT', selectedAddressSAPFormat);
                }
            }
            this.sendAnalytics('ctaProcedi', null);
        }
    }

    /*
    * Applica nuovo indirizzo di consegna scelto dalla lista di indirizzi salvati
    *
    *  */
    async applyNewAddressModal() {
        let newAddressData = JSON.parse(decodeURI(_$('.list-address-user li input:checked').closest('li').attr('data-address')));
        let newAddressDataFormattedAddress = newAddressData.formattedAddress;
        _$('#googleInputOnboardingCheckoutLine1').val(newAddressDataFormattedAddress);
        _$('#googleInputOnboardingCheckoutLine1').closest('.google-input').find('.submitButton').trigger('click');
        _Uikit.modal('#modalModificaIndirizzoSceltaIndirizzo').hide();
    }

    addAddressModal() {
        this.bookUtils.bookingSlotState = BOOKING_SLOT_STATE.MODIFY_ADDRESS;
        this.bookUtils.checkoutAddingNewAddress = true;
        this.onboardingManager.manageModalSteps('step1Checkout', null, null, true);
    }

    /* recupera la modale elenco prodotti da servlet */
    loadModalElencoProdotti(){
        let link = _$('.primary-panel').attr('data-product-overlay');
        let success = (res) => {
            this.utils.showLoader('', false);
            if(_$('#modalElencoProdotti').length){
                _$('#modalElencoProdotti').replaceWith(res);
            }else{
                _$('.checkout-container').append(res);
            }
            this.setModalElencoProdottiAnalytics();
            _Uikit.modal('#modalElencoProdotti').show();
            this.sendAnalytics('ctaListaProdotti');
        }

        let error = (err) => {
            this.utils.showLoader('', false);
            console.error(err);
        }

        this.utils.showLoader('', true);
        this.cartService.getPageUpdate(success, error, link);
    }

    /**
     * Checks value
     * @param type
     */
    checkValueFieldGuest(event, type: string) {
        this.setValueFieldForm(event, type);
        
        this._validationUtils.newValidInput(event);
    }

    checkValueFieldGuestAnalytic(event){
        if (_$(event).hasClass('uk-form-danger')) {
            this.sendAnalytics('erroreForm', '');
        }
    }

    isValidForm() {
        let form = _$('.box-info-content-guest .form');

        let $allInputs = form.find('input');
        let validInputs = [];

        _$($allInputs).each((index, singleInput) => {
            let $input = _$(singleInput);
            this._validationUtils.newValidInput($input);
            if ($input.attr('data-valid') == 'false') {
                validInputs.push(false);
            } else {
                validInputs.push(true);
            }
        });

        return validInputs.indexOf(false) < 0;
    }

    sendEmailGuest(){
        if (this.isValidForm()) {
            let success = (res) => {
                _$('#email-error-2').addClass('uk-hidden');
                _$('#email-error-generic').addClass('uk-hidden');
                this.localStorage.setItem('actionOnGuestOrder', JSON.stringify('checkoutGuest'));
                Window.location.href = Window.urlSite.checkoutRecognized;
            }

            let error = (err) => {
                if(err && err.response && err.response.data && err.response.data.code){
                    switch (err.response.data.code){
                        case 'BACKEND:EMAIL_ALREADY_REGISTERED_ERROR':
                            _$('#email-error-generic').addClass('uk-hidden');
                            _$('#email-error-2').removeClass('uk-hidden');
                            break;
                        case 'WEB:INPUT_PARSING_ERROR':
                            _$('#email-error-generic').removeClass('uk-hidden');
                            _$('#email-error-2').addClass('uk-hidden');
                            _$('#email-error-generic').find('p').html('Errore invio dati');
                        default:
                            _$('#email-error-generic').removeClass('uk-hidden');
                            _$('#email-error-2').addClass('uk-hidden');
                            _$('#email-error-generic').find('p').html('Errore generico');
                    }
                }
            }
            
            let body = {
                'email': _$('.box-info-content-guest #emailGuest') ? _$('.box-info-content-guest #emailGuest').val().toString().trim() : ''
            }
            
            this.userService.checkoutUserInfo(success, error, body);
        }
    }

    updatePersonalOrAddressForm(input, deliveryFormField){
        if(Window.typeOfService != null && Window.typeOfService == 'HOME_DELIVERY'){
            switch(deliveryFormField){
                case 'lift':
                    this._deliveryForm[deliveryFormField] = _$(input).find('.checkmark').hasClass('selected');
                    this.fetchDeliveryFormData('personalForm');
                    this.fetchDeliveryFormData('addressForm');
                    if(Window.user){
                        if(this._deliveryForm && this._deliveryForm['id'] != null && this._deliveryForm['id'] != ''){
                            this.submitStepDelivery(true);
                        }else{
                            this.setAddressCart(this._deliveryForm, true);
                        }
                    }else{
                        this.setAddressCart(this._deliveryForm, true);
                    }
                    break;
                case 'reception':
                    this._deliveryForm[deliveryFormField] = _$(input).find('.checkmark').hasClass('selected');
                    break;
                case 'floor':
                    this._deliveryForm[deliveryFormField] = _$(input).val();
                    this.fetchDeliveryFormData('personalForm');
                    this.fetchDeliveryFormData('addressForm');
                    if(Window.user){
                        if(this._deliveryForm && this._deliveryForm['id'] != null && this._deliveryForm['id'] != ''){
                            this.submitStepDelivery(true);
                        }else{
                            this.setAddressCart(this._deliveryForm, true);
                        }
                    }else{
                        this.setAddressCart(this._deliveryForm, true);
                    }

                    break;
                default:
                    this._deliveryForm[deliveryFormField] = _$(input).val();
                    break;
            }
        }
    }

    async proceedToPayment(){
        if(Window.typeOfService != null && Window.typeOfService == 'ORDER_AND_COLLECT' && this.isValidFormContact()){
            try{
                if(this.showModalUpdatedQuantity){
                    this.showModalUpdatedQuantity = false;
                    _Uikit.modal('#modal-quantita-aggiornata').show();
                }else{
                    this.sendAnalytics('confermaOrdine', null);
                    let result = await this.submitContactDetails();
                    if(!Window.cart?.parentOrder || (Window.cart?.parentOrder && Window.cart?.paymentMode == "PAYMENT_ONLINE")){
                        let iframeResult = this.fetchWineptsIframe().then((data) => {
                            if(data)
                                this.goToStepPayment();
                        }).catch((error) => {
                            console.error('Error fetching winepts Iframe')
                        });
                    }else{
                        this.goToStepPayment();
                    }
                }
            }catch(error){
                console.error(error);
            }
            
        }else{
            let validContacts = this.isValidFormContact();
            let validDelivery = this.isValidFormDelivery();
            try{
                if(validContacts && validDelivery){
                    if(this.showModalUpdatedQuantity){
                        this.showModalUpdatedQuantity = false;
                        _Uikit.modal('#modal-quantita-aggiornata').show();
                    }else{
                        this.sendAnalytics('confermaOrdine', null);
                        let result = await this.submitStepDelivery();
                        let iframeResult = this.fetchWineptsIframe().then((data) => {
                            if(data)
                                this.goToStepPayment();
                        }).catch((error) => {
                            console.error('Error fetching winepts Iframe')
                        });
                    }
                }else{
                    let form = validContacts ? _$('#addressForm') : _$('#personalForm');

                    let $allInputs = form.find('input[required], select[required]');
                    let firstFieldNotCompiled;

                    _$($allInputs).each((index, singleInput) => {

                        let $input = _$(singleInput);
                        this.controlValueFieldInput($input,'','addressForm');
                        if ($input.attr('data-valid') == 'false') {
                            firstFieldNotCompiled = $input;
                            return;
                        }
                    });
                        
                    _$(window).scrollTop(_$(firstFieldNotCompiled).offset().top - 300);
                }
            }catch(error){
                console.error(error);
            }
        }
    }

    /**
     * Salva Dati di contatto ORitira
     * @param el
     */
    submitContactDetails(): Promise<void> {
        return new Promise((resolve, reject) => {
            let body = {}
            body['firstname'] = _$('#personalForm').find('#c_s1_firstName').val().toString();
            body['lastname'] = _$('#personalForm').find('#c_s1_lastName').val().toString();
            body['phone'] = _$('#personalForm').find('#c_s1_cellphone').val().toString();
            if(!Window.user){
                body['email'] = _$('#personalForm').find('#c_s1_email').val().toString();
            }

            let success = (res) => {
                this.utils.showLoader('', false);
                this.userName = _$('#personalForm').find('#c_s1_firstName').val().toString();
                this.userSurname = _$('#personalForm').find('#c_s1_lastName').val().toString();
                this.userPhone = _$('#personalForm').find('#c_s1_cellphone').val().toString();
                this.userEmail = _$('#personalForm').find('#c_s1_email').val().toString();
                resolve();
            }

            let error = (err) => {
                this.utils.showLoader('', false);
                if(err && err.response && err.response.data && err.response.data.code){
                    switch (err.response.data.code){
                        case 'BACKEND:EMAIL_ALREADY_REGISTERED_ERROR':
                            _$('#c_s1_email').attr('data-valid', 'false')
                            _$('#c_s1_email').siblings('.message-error').text('Indirizzo email già registrato, cambia indirizzo email.');
                            _$('#c_s1_email').siblings('.message-error').removeClass('uk-hidden');
                            _$('#c_s1_email').addClass('uk-form-danger');
                            _$('#c_s1_email').parent().css('margin-bottom', '27px');
                            break;
                        default:
                            _$('#c_s1_email').attr('data-valid', 'false')
                            _$('#c_s1_email').siblings('.message-error').text('Errore generico durante il salvataggio dei dati');
                            _$('#c_s1_email').siblings('.message-error').removeClass('uk-hidden');
                            _$('#c_s1_email').addClass('uk-form-danger');
                            _$('#c_s1_email').parent().css('margin-bottom', '27px');
                            break;
                    }
                }
                reject();
            }

            this.utils.showLoader('', true);
            this.userService.checkoutUserInfo(success, error, body);
        })
        
    }

    /**
     * Salva Step 1. Consegna/Punto di ritiro
     */
    async submitStepDelivery(fromUpdatePersonalOrAddressForm: boolean = false): Promise<void> {
        return new Promise((resolve, reject) => {
            if (Window.user) {
                let editingAddress = false;
                this.utils.showLoader('', true);
                let success = async (res) => {
                    this.utils.showLoader('', false);
                    if(!editingAddress){
                        this.bookUtils._deliveryAddressesCount += 1;
    
                        if(res.data.data.id){
                            this._deliveryForm['id'] = res.data.data.id;
                            this.selectedMyconadAddressId = res.data.data.id;
                        }
                    }
                    
                    this._deliveryForm['cellphoneVerified'] = true;
                    await this.setAddressCart(this._deliveryForm, false, !fromUpdatePersonalOrAddressForm);
                    this._dataUserAddressPreview = res.data.data.summary + ' | ' + this.userEmail;
                    resolve();
                };
                let error = (err) => {
                    this.utils.showLoader('', false);
                    console.log(err);
                    reject();
                };
    
                this._deliveryForm['cellphoneVerified'] = true;
                this.fetchDeliveryFormData('personalForm');
                this.fetchDeliveryFormData('addressForm');
                if(this._deliveryForm && this._deliveryForm['id'] != null && this._deliveryForm['id'] != ''){
                    editingAddress = true;
                    this.userService.editUserDeliveryAddress(success, error, this._deliveryForm);
                }else{
                    this.userService.addUserDeliveryAddress(success, error, this._deliveryForm);
                }
                
            } else {
                try{
                    let saveGuestData = async () => {
                        let response = await this.updateGuestEmail();
                        this._deliveryForm['cellphoneVerified'] = true;
                        this.fetchDeliveryFormData('personalForm');
                        this.fetchDeliveryFormData('addressForm');
                        await this.setAddressCart(this._deliveryForm, false, true);
                        resolve();
                    }
                    
                    saveGuestData();
                }catch(error){
                    if(error){
                        switch (error){
                            case 'BACKEND:EMAIL_ALREADY_REGISTERED_ERROR':
                                _$('#c_s1_email').attr('data-valid', 'false')
                                _$('#c_s1_email').siblings('.message-error').text('Indirizzo email già registrato, cambia indirizzo email.');
                                _$('#c_s1_email').siblings('.message-error').removeClass('uk-hidden');
                                _$('#c_s1_email').addClass('uk-form-danger');
                                _$('#c_s1_email').parent().css('margin-bottom', '27px');
                                break;
                            default:
                                _$('#c_s1_email').attr('data-valid', 'false')
                                _$('#c_s1_email').siblings('.message-error').text('Errore generico durante il salvataggio della mail');
                                _$('#c_s1_email').siblings('.message-error').removeClass('uk-hidden');
                                _$('#c_s1_email').addClass('uk-form-danger');
                                _$('#c_s1_email').parent().css('margin-bottom', '27px');
                                break;
                        }
                    }else{
                        _$('#c_s1_email').attr('data-valid', 'false')
                        _$('#c_s1_email').siblings('.message-error').text('Errore generico durante il salvataggio della mail');
                        _$('#c_s1_email').siblings('.message-error').removeClass('uk-hidden');
                        _$('#c_s1_email').addClass('uk-form-danger');
                        _$('#c_s1_email').parent().css('margin-bottom', '27px');
                    }
                    reject();
                }
                
            }
        })
    }

    updateGuestEmail(): Promise<void>{
        return new Promise((resolve, reject) => {
            let body = {
                email: _$('#c_s1_email').val()
            }
    
            let success = (res) => {
                this.utils.showLoader('', false);
                this.userEmail = _$('#c_s1_email').val() && _$('#c_s1_email').val().toString() ? _$('#c_s1_email').val().toString() : '';
                resolve();
            }
    
            let error = (err) => {
                this.utils.showLoader('', false);
                if(err && err.response && err.response.data && err.response.data.code){
                    reject(err.response.data.code)
                }else{
                    reject();
                }
                
            }

            this.utils.showLoader('', true);
            this.userService.checkoutUserInfo(success, error, body);  
        });
    }

    isValidFormDelivery() {
        
        let form = _$('#addressForm');

        let $allInputs = form.find('input, select, textarea');
        let validInputs = [];

        _$($allInputs).each((index, singleInput) => {

            let $input = _$(singleInput);
            this.controlValueFieldInput($input,'','addressForm');
            if ($input.attr('data-valid') == 'false') {
                validInputs.push(false);
            } else {
                validInputs.push(true);
            }
        });
        return validInputs.indexOf(false) < 0;
    }

    isValidFormContact() {
        let form = _$('#personalForm');

        let $allInputs = form.find('input');
        let validInputs = [];

        _$($allInputs).each((index, singleInput) => {
            let $input = _$(singleInput);
            this.controlValueFieldInput($input,'','personalForm');
            if ($input.attr('data-valid') == 'false') {
                validInputs.push(false);
            } else {
                validInputs.push(true);
            }
        });
        return validInputs.indexOf(false) < 0;
    }

    /**
     * Click CTA Procedi al pagamento
     */
    checkoutProceed() {
        if(this.checkoutProceedCheck() && !this.checkoutPressed){
            this.checkoutPressed = true;
            let success = (res) => {
                this.utils.showLoader('', false);
                console.log(res);
                if (res.data) {
                    if (res.data.url) {
                        console.log('res.data.url')
                        window.location.href = res.data.url;

                    } else if (res.data.data) {
                        if (res.data.data.url) {
                            console.log('res.data.data.url')
                            window.location.href = res.data.data.url;
                        }
                    }
                } else if (res.url) {
                    console.log('res.url')
                    window.location.href = res.url;
                } else {
                    console.log('res');
                    window.location.href = res;
                }
            };
    
            let error = (err) => {
                console.error(err);
                _$('.sezione-paga-in-negozio .btn-prosegui-pagamento').prop('disabled', false);
                this.utils.showLoader('', false);
                this.checkoutPressed = false;
    
                this.managePaymentUrlError(err);
            };
    
            let body = {
                salesConditions: !!_$('#checkout-sales-conditions').find('input[type="checkbox"]').is(':checked'),
                paymentMethod: 'PAYMENT_IN_PDV',
                visitorID: this.utils.getVisitorId(),
                suggestedDeliveryDate: this.fetchGiornoPropostoCheckout()
            };
    
            this.utils.showLoader('', true);
            _$('.sezione-paga-in-negozio .btn-prosegui-pagamento').prop('disabled', true);
            this.cartService.checkout(success, error, body);
            this.sendAnalytics('confermaPagamentoNegozio', null);
        }
    }

    checkoutProceedCheck() {
        _$('#checkout-sales-conditions')[0].setAttribute("aria-pressed", "true");
        _$('#checkout-sales-conditions').find('.checkmark').addClass('selected');
        _$('#checkout-sales-conditions').find('input')[0].checked = true;
        return true;

        /* if(_$('#checkout-sales-conditions').find('input').is(':checked')){
            return true;
        }else{
            _$('#checkout-sales-conditions').find('.checkmark').addClass('error-required-checkout');
            _$('#checkout-sales-conditions-message-error').removeClass('uk-hidden');
            return false;
        } */
    }

    /* Apre la modal Checkout error popup */
    openCheckoutErrorPopupModal() {
        let title = 'Ops! Fascia oraria scaduta.';
        let descHD = 'Hai superato il tempo limite per la fascia oraria di consegna selezionata. Prenota un nuovo orario di consegna per concludere il tuo ordine.';
        let descOC = 'Hai superato il tempo limite per la fascia oraria di ritiro selezionato. Prenota un nuovo orario di ritiro per concludere il tuo ordine.';
        _Uikit.modal("#modalCheckoutPopupError").show();

        if (Window.typeOfService === 'HOME_DELIVERY') {
            _$('#modalCheckoutPopupError').find('.dynamicTitle').text(title);
            _$('#modalCheckoutPopupError').find('.dynamicSubtitle').text(descHD);

        } else if (Window.typeOfService === 'ORDER_AND_COLLECT') {
            _$('#modalCheckoutPopupError').find('.dynamicTitle').text(title);
            _$('#modalCheckoutPopupError').find('.dynamicSubtitle').text(descOC);
        }
    }

    fetchGiornoPropostoCheckout(): boolean{
        try{
            if(Window.selectedTimeslot && Window.selectedTimeslot.date && Window.pointOfService && Window.pointOfService.firstAvailableTimeSlot && Window.pointOfService.firstAvailableTimeSlot.date){
                let selected = new Date(Window.selectedTimeslot.date);
                let firstAvailable = new Date(Window.pointOfService.firstAvailableTimeSlot.date);
                if(selected.toDateString() === firstAvailable.toDateString()){
                    return true;
                }else{
                    return false;
                }
            }else{
                return false;
            }
        }catch{
            return false;
        }
    }

    /**
     * Recupero il valore della password
     * @param e
     * @param type
     */
    setValueInput(e, type) {
        switch (type) {
            case 'password':
                this.valuePassword = e.value;
                break;
        }

        if (this.valuePassword) {
            _$('.js-button-login').removeAttr('disabled');
        } else {
            _$('.js-button-login').attr('disabled', 'true');
        }
    }

    async getLoginPersistente(e) {
        await this.callLogin(e);
    }

    /**
     * Login Persistente email
     * @param e
     */
    callLogin(e): Promise<void> {
        return new Promise((resolve, reject) => {
            let errorMessage = document.getElementsByClassName('js-alert-login')[0];
            let inputFields = _$('.component-modal-login input');
            this.utils.showLoader(_$(e), true, LOADER_POSITION.BUTTON);
            let success = (res) => {
                this.utils.showLoader(_$(e), false, LOADER_POSITION.BUTTON);
                if (res) {
                    errorMessage.classList.add('uk-hidden');
                    inputFields.removeClass('uk-form-danger');
                    _Uikit.offcanvas('#offcanvasLogin').hide();

                    if (this.origin == 'procedi') {
                        this.checkoutProceed();
                    } else if (this.origin == 'addAddress') {
                        this.addNewUserAddress();
                    }
                } else {
                    errorMessage.classList.remove('uk-hidden');
                    inputFields.addClass('uk-form-danger');
                }
                resolve();
            };
            let error = (err) => {
                this.utils.showLoader(_$(e), false, LOADER_POSITION.BUTTON);
                errorMessage.classList.remove('uk-hidden');
                inputFields.addClass('uk-form-danger');
                reject();
            };
            let params = {
                email: this.valueEmail ? this.valueEmail : '',
                password: this.valuePassword ? this.valuePassword : ''
            };
            this.userService.doLoginConfirm(success, error, params);
        });
    }

    /**
     * Controllo metodi di pagamento disponibili
     */
    checkPaymentOptions(){
        if(!_$('#step-2-paga-negozio').length){
            _$('.page-CheckoutNew .menu-pagamento').addClass('uk-hidden');
        }
    }

    checkTimeslotExpired(): boolean{
        let expDate = new Date(Window.selectedTimeslotExpiration);
        let currentDate = new Date();
        currentDate.setTime(currentDate.getTime() - currentDate.getTimezoneOffset() * 60 * 1000);
        expDate.setTime(expDate.getTime() - expDate.getTimezoneOffset() * 60 * 1000);
        let timer = expDate.getTime() - currentDate.getTime() > 0 ? expDate.getTime() - currentDate.getTime() : 0;
        if(timer > 0){
            return false;
        }else{
            return true;
        }
    }

    async fetchWineptsIframe(): Promise<boolean>{
        return new Promise((resolve, reject) => {
            let success = (res) => {
                this.utils.showLoader('', false);

                //url: o url iframe o url redirect
                let url;

                if (res?.data?.data) {
                    url = res.data.data.url;

                    if(res.data.data.isFullRedirect) {
                        //if ordine senza preautorizzazione richiesta (redirect to TYPage)
                        window.location.href = url;

                    } else {

                        //if ordine senza preautorizzazione con richiesta (redirect to iFrame)
                        _$('#iframe-payment-winepts').attr('src', url);
                        if(url != null && url != undefined && url != ''){
                            resolve(true);
                        }else{
                            reject();
                        }
                    }
                }
            }
    
            let error = (err) => {
                console.error(err);
                this.backToStepData();
                this.utils.showLoader('', false);
                this.managePaymentUrlError(err);
                reject();
            }
    
            let body = {
                salesConditions: true,
                paymentMethod: 'PAYMENT_ONLINE',
                visitorID: this.utils.getVisitorId(),
                suggestedDeliveryDate: this.fetchGiornoPropostoCheckout()
            };
    
            this.utils.showLoader('', true);
            this.cartService.checkout(success, error, body);
        }) 
    }


    wineptsIframeListener(){
        window.addEventListener('message', (e) => {
            let action = 'none';
            let url = '';
            let iframeWindow = document.getElementById('iframe-payment-winepts') as HTMLIFrameElement;
            let contentWindow = iframeWindow != null ? iframeWindow.contentWindow : null;
            if(iframeWindow != null && contentWindow != null && e != null && e.source == contentWindow && e.data != null && JSON.parse(e.data) != null){
                let postMessageData = JSON.parse(e.data);
                
                url = postMessageData.url != null ? postMessageData.url : '';
                console.log('postMessageData: ' + JSON.stringify(postMessageData));
                console.log('raw post message: '+ JSON.stringify(e));
                action = postMessageData.action;
                
                switch(action){
                    case 'reload':
                        Window.location.reload();
                        break;
                    case 'redirect':
                        Window.location.href = url;
                        break;
                    default:
                        console.log('default');
                        break;
                }
            }

            
        });
    }

    setModalElencoProdottiAnalytics(){
        if(_$('#modalElencoProdotti').length){
            _$('#modalElencoProdotti').on('show', () => {
                this.sendAnalytics('pageViewListaProdotti');
            });

            _$('#modalElencoProdotti').on('hide', () => {
                this.sendAnalytics('chiusuraModaleListaProdotti');
            });
        }
    }

    setModalQuantitaAggiornataAnalytics(){
        
        if(_$('#modal-quantita-aggiornata').length){
            _$('#modal-quantita-aggiornata').on('show', () => {
                this.sendAnalytics('pageviewModalQuantitaAggiornata');
            });

            _$('#modal-quantita-aggiornata').find('.btn-group a').on('click', () => {
                this.sendAnalytics('backCarrelloAggiornamentoProdotti');
            });

            _$('#modal-quantita-aggiornata').on('hide', () => {
                this.sendAnalytics('chiudiPopupAggiornamentoProdotti');
            });
        }
    }

    fetchDeliveryFormData(formId){
        let form = document.getElementById(formId) as HTMLFormElement;
        if(form != null){
            let formData = new FormData(form);
            formData.forEach((value, key) => {
                switch(key){
                    case 'c_s1_firstName':
                        this._deliveryForm['firstName'] = value;
                        break;
                    case 'c_s1_lastName':
                        this._deliveryForm['lastName'] = value;
                        break;
                    case 'c_s1_cellphone':
                        this._deliveryForm['cellphone'] = value;
                        break;
                    case 'c_s1_nickname':
                        this._deliveryForm['nickname'] = value;
                        break;
                    case 'c_s1_doorbell':
                        this._deliveryForm['doorbell'] = value;
                        break;
                    case 'c_s1_building':
                        this._deliveryForm['building'] = value;
                        break;
                    case 'c_s1_remarks':
                        this._deliveryForm['remarks'] = value;
                        break;
                }
            });
        }else{
            console.error('form data null')
        }
    }

    setAnalyticsScontrino(){
        if(_$('#scontrino-detail').length){
            _$('#scontrino-detail').on('show', () => {
                this.sendAnalytics('aperturaScontrino');
            });
        }
    }

    async manageDeliveryAreaError(){
        let stepCheckout = true;   
        this.localStorage.setItem('step2Flow', JSON.stringify('modifyStep2'));

        this.localStorage.setItem('selectedAddress_SAP_Format', JSON.stringify(Window.cart?.deliveryAddress));
        try{
            if(this.missingDeliveryAddressFromCart()){
                this.onboardingManager.manageModalSteps('step1Checkout', 'HOME_DELIVERY', Window.cart?.deliveryAddress);
            }else{
                let stores = await this.google.fetchStores(Window.cart?.deliveryAddress, Window.typeOfService, stepCheckout);
                if(stores != null && stores == 'true'){
                    let stopOnPdvList = true;
                    this.google.loadStores(Window.typeOfService, false, stepCheckout, Window.cart?.deliveryAddress, stopOnPdvList);
                    if(Window.pointOfService?.name){
                        this.onboardingManager.togglePreselectedPdv(Window.typeOfService, Window.pointOfService.name)
                    }
                }else if(stores != null && stores == 'false'){
                    this.onboardingManager.manageModalSteps('step1Checkout', 'HOME_DELIVERY', Window.cart?.deliveryAddress);
                }
            }
        }catch{
            this.onboardingManager.manageModalSteps('step1Checkout', 'HOME_DELIVERY', Window.cart?.deliveryAddress);
        }
    }

    managePaymentUrlError(err){
        const errorCode = err?.response?.data?.code;
        switch(errorCode){
            case 'BACKEND:CUT_OFF_ERROR':
                this.openCheckoutErrorPopupModal();
                break;
            case 'BACKEND:DELIVERY_AREA_ERROR':
                _Uikit.modal('#modalCheckoutDeliveryAreaError').show();
                break;
            case 'BACKEND_NO_ONLINE_PAYMENT_GUEST':
                _Uikit.modal('#modalCheckoutPDVOnly').show();
                _$('#modalCheckoutPDVOnly').addClass('type-a');
                break;
            case 'BACKEND_NO_ONLINE_PAYMENT_OTHER':
                _Uikit.modal('#modalCheckoutPDVOnly').show();
                _$('#modalCheckoutPDVOnly').addClass('type-b');
                break;
            case 'BACKEND_NO_ONLINE_PAYMENT_PROCEED':
                _$('.paga-online').addClass('uk-hidden');
                this.menuPagamento(_$('.menu-pagamento .paga-in-negozio'));
                this.goToStepPayment();
            default:
                break;
        }
    }

    openWarningAggiungiProdotti(){
        if(_$('.warning-aggiungi-prodotti').length){

            _$('.warning-aggiungi-prodotti').on('click', (event) => {
                event.stopImmediatePropagation();
            });
            setTimeout(() => {
                _$('.warning-aggiungi-prodotti .tooltiptext-aggiungi-prodotti').prev().trigger('click');
            }, 100);
        }
    }

    async sendAnalytics(eventType, page?) {
        try {
            let tsdEnabled = false;
            if(Window.pointOfService && Window.pointOfService.tsdEnabled){
                tsdEnabled = true;
            }
            let analyticsData;
            let step;
            let tipoServizio = Window.typeOfService == 'ORDER_AND_COLLECT' ? 'Ordina e ritira' : 'Ordina e ricevi';
            let selectedDay, selectedTimeSlot, giornoProposto;
            let selectedDayOnPage, selectedTimeSlotOnPage, giornoPropostoOnPage;
            let slotDinamico, slotDinamicoScontato;
            let slotDinamicoOnPage, slotDinamicoScontatoOnPage;
            selectedDay = _$('.component-card-timeslot.selected').attr('data-date') ? _$('.component-card-timeslot.selected').attr('data-date') : '';
            giornoProposto = _$('.component-card-timeslot.available').first().attr('data-date') == selectedDay ? 'SI' : 'NO';

            selectedDayOnPage = Window.selectedTimeslot && Window.selectedTimeslot.date && _moment(Window.selectedTimeslot.date) != null ? 
                                _moment(Window.selectedTimeslot.date).utc() : '';
            giornoPropostoOnPage =  Window.selectedTimeslot && Window.selectedTimeslot.date
                                && Window.pointOfService && Window.pointOfService.firstAvailableTimeSlot && Window.pointOfService.firstAvailableTimeSlot.date &&
                                Window.selectedTimeslot.date == Window.pointOfService.firstAvailableTimeSlot.date ? 'SI' : 'NO';
            selectedTimeSlotOnPage = Window.selectedTimeslot && Window.selectedTimeslot.startTime && Window.selectedTimeslot.endTime ? 
                                        Window.selectedTimeslot.startTime.formattedHour + '-' + Window.selectedTimeslot.endTime.formattedHour : '';

            slotDinamicoOnPage = Window.selectedTimeslot && Window.selectedTimeslot.tipologiaFascia ? Window.selectedTimeslot.tipologiaFascia : '';
            slotDinamicoScontatoOnPage = Window.selectedTimeslot && Window.selectedTimeslot.deliveryCost != null && Window.selectedTimeslot.discountedDeliveryCost != null &&
                                            Window.selectedTimeslot.discountedDeliveryCost < Window.selectedTimeslot.deliveryCost ? 'SI' : 'NO'

            if(tsdEnabled){
                let timeslotData;
                if(Window.tsdUseSlider){
                    selectedTimeSlot = _$('.component-SliderTimeslotTsd.active').attr('data-hour') ? _$('.component-SliderTimeslotTsd.active').attr('data-hour') : '';
                    timeslotData = _$('.component-SliderTimeslotTsd.active').attr('data-timeslot') != null ? JSON.parse(_$('.component-SliderTimeslotTsd.active').attr('data-timeslot')) : null;   
                }else{
                    timeslotData = _$('.chip-hour.selected').attr('data-timeslot') != null ? JSON.parse(_$('.chip-hour.selected').attr('data-timeslot')) : null;
                    selectedTimeSlot = _$('.chip-hour.selected').attr('data-hour') ? _$('.chip-hour.selected').attr('data-hour') : '';
                }
                if(timeslotData){
                    slotDinamico = timeslotData['tipologiaFascia'];
                    slotDinamicoScontato = timeslotData['deliveryCost'] != null && timeslotData['discountedDeliveryCost'] != null && timeslotData['deliveryCost'] > timeslotData['discountedDeliveryCost'] ? 'SI' : 'NO';
                }
            }else{
                selectedTimeSlot = _$('.chip-hour.selected').attr('data-hour') ? _$('.chip-hour.selected').attr('data-hour') : '';
            }

            let importo = window['cart'] && window['cart'].subTotal ? window['cart'].subTotal.value : '0';
            let importoConsegna;
            let consegnaGratuita; 
            let productCards = _$('.box-scontrino .scroll-int .uk-card');

            let prodotti = []
            let storeSelected = this.localStorage.getFromStorage('storeSelected');

            if(_$('.box-scontrino').find('.total-delivery-costs-value').length && tipoServizio == 'Ordina e ricevi'){
                importoConsegna = parseFloat(_$('.box-scontrino').find('.total-delivery-costs-value').html().replace('€','').replace(',','.'));
                if(importoConsegna != null && importoConsegna > 0){
                    consegnaGratuita = 'NO'
                }else{
                    consegnaGratuita = 'SI'
                };
            }else{
                importoConsegna = 0;
                consegnaGratuita = 'SI';
            }

            for (let i = 0; i < productCards.length; i++) {
                prodotti.push((await this._analytics.getProductData(productCards.eq(i))));
            }

            let codiceCoupon = "";
            if (_$('.coupon-applied-list').length && _$('.coupon-applied-list').find(".voucher-id").length) {
                _$('.coupon-applied-list').find(".voucher-id").each((index, element) => {
                    codiceCoupon = codiceCoupon + _$(element).html() + "|" ;
                })
                codiceCoupon = codiceCoupon.slice(0, -1);
            }

            switch (eventType) {
                case 'pageview':
                    if (page == 'checkout-guest') {
                        //4.3.1
                        analyticsData = {
                            event: eventType,
                            carrello: this.localStorage.getFromStorage('carrelloAnalytics')
                        }
                        step = 'FUNNEL_DATA_CHECKOUT';
                    } else if (page == 'checkout-container') {
                        //4.3.5
                        analyticsData = {
                            event: eventType,
                            IDpromozioni: this._analytics.getIDpromozioni(),
                            carrello: {
                                importo,
                                consegnaGratuita,
                                importoConsegna,
                                prodotti: this.localStorage.getFromStorage('carrelloAnalytics') ? this.localStorage.getFromStorage('carrelloAnalytics')['prodotti'] : ''
                            }
                        }
                        step = 'FUNNEL_DATA_CHECKOUT_PAGEVIEW_HD';
                    } else if (page == 'offcanvasCheckoutAddressDeliveryList') {
                        //4.3.16
                        analyticsData = {
                            event: eventType,
                            carrello: {
                                importo,
                                consegnaGratuita,
                                importoConsegna
                            }
                        }

                        if (selectedDayOnPage && selectedTimeSlotOnPage && giornoPropostoOnPage) {
                            analyticsData['funnel'] = {};
                            analyticsData['funnel']['sceltaSlot'] = {
                                giorno: selectedDayOnPage,
                                fasciaOraria: selectedTimeSlotOnPage,
                                giornoProposto: giornoPropostoOnPage,
                            }
                        }
                        step = 'FUNNEL_DATA_CHECKOUT_MODAL_ADDRESSES_LIST';
                    }

                    break;
                //1.1.4
                case 'pageview-step2':
                    analyticsData = {
                        event: 'pageview',
                        carrello: {
                            importo,
                            consegnaGratuita,
                            importoConsegna,
                            prodotti: this.localStorage.getFromStorage('carrelloAnalytics') ? this.localStorage.getFromStorage('carrelloAnalytics')['prodotti'] : ''
                        },
                        checkout: {
                            indirizzo: {
                                indirizzoPredefinito: this._deliveryForm ? (this._deliveryForm['defaultAddress'] ? 'SI' : 'NO') : ''
                            }
                        }
                    }

                    if (selectedDayOnPage && selectedTimeSlotOnPage && giornoPropostoOnPage) {
                        analyticsData['funnel'] = {};
                        analyticsData['funnel']['sceltaSlot'] = {
                            giorno: selectedDayOnPage,
                            fasciaOraria: selectedTimeSlotOnPage,
                            giornoProposto: giornoPropostoOnPage,
                        }
                    }

                    if(tsdEnabled){
                        analyticsData.funnel.sceltaSlot['slotDinamico'] = slotDinamicoOnPage;
                        analyticsData.funnel.sceltaSlot['slotDinamicoScontato'] = slotDinamicoScontatoOnPage;
                    }

                    step = 'FUNNEL_DATA_CHECKOUT_PAGEVIEW_STEP2';
                    break;

                //1.1.3
                case 'aperturaScontrino':
                    analyticsData = {
                        event: eventType,
                        carrello: {
                            importo,
                            consegnaGratuita,
                            importoConsegna,
                            prodotti: this.localStorage.getFromStorage('carrelloAnalytics') ? this.localStorage.getFromStorage('carrelloAnalytics')['prodotti'] : ''
                        }
                    }

                    if (selectedDayOnPage && selectedTimeSlotOnPage && giornoPropostoOnPage) {
                        analyticsData['funnel'] = {};
                        analyticsData['funnel']['sceltaSlot'] = {
                            giorno: selectedDayOnPage,
                            fasciaOraria: selectedTimeSlotOnPage,
                            giornoProposto: giornoPropostoOnPage,
                        }
                    }

                    if(tsdEnabled){
                        analyticsData.funnel.sceltaSlot['slotDinamico'] = slotDinamicoOnPage;
                        analyticsData.funnel.sceltaSlot['slotDinamicoScontato'] = slotDinamicoScontatoOnPage;
                    }

                    step = 'FUNNEL_DATA_CHECKOUT_PAGEVIEW_HD';
                    break;
                
                //1.1.5:
                case 'confermaPagamentoNegozio':
                    analyticsData = {
                        event: eventType,
                        carrello: {
                            importo,
                            consegnaGratuita,
                            importoConsegna,
                            prodotti: this.localStorage.getFromStorage('carrelloAnalytics') ? this.localStorage.getFromStorage('carrelloAnalytics')['prodotti'] : ''
                        },
                        checkout: {
                            indirizzo: {
                                indirizzoPredefinito: this._deliveryForm ? (this._deliveryForm['defaultAddress'] ? 'SI' : 'NO') : ''
                            },
                            pagamento: {
                                metodoPagamento: 'Paga in negozio',
                                codiceCoupon
                            },
                            fatturazione: {
                                flagRichiesta: _$('#checkout_envoice_input').is(':checked') ? 'SI' : 'NO',
                                tipologiaIntestatario: this.localStorage.getFromStorage('tipoIntest'),
                                indirizzoPreferito: this.localStorage.getFromStorage('indirizzoPref')
                            }
                        }
                    }

                    if (selectedDayOnPage && selectedTimeSlotOnPage && giornoPropostoOnPage) {
                        analyticsData['funnel'] = {};
                        analyticsData['funnel']['sceltaSlot'] = {
                            giorno: selectedDayOnPage,
                            fasciaOraria: selectedTimeSlotOnPage,
                            giornoProposto: giornoPropostoOnPage,
                        }
                    }

                    if(tsdEnabled){
                        analyticsData.funnel.sceltaSlot['slotDinamico'] = slotDinamicoOnPage;
                        analyticsData.funnel.sceltaSlot['slotDinamicoScontato'] = slotDinamicoScontatoOnPage;
                    }
                    step = 'FUNNEL_DATA_CHECKOUT_CONFERMA_PAGA_IN_NEGOZIO';
                    break;
                //1.1.6
                case 'ctaListaProdotti':
                    analyticsData = {
                        event: eventType,
                        carrello: {
                            importo,
                            consegnaGratuita,
                            importoConsegna,
                            prodotti: this.localStorage.getFromStorage('carrelloAnalytics') ? this.localStorage.getFromStorage('carrelloAnalytics')['prodotti'] : ''
                        },
                        checkout: {
                            indirizzo: {
                                indirizzoPredefinito: this._deliveryForm ? (this._deliveryForm['defaultAddress'] ? 'SI' : 'NO') : ''
                            }
                        }
                    }

                    step = 'FUNNEL_DATA_CHECKOUT_PAGEVIEW_HD';
                    break;
                //1.1.7
                case 'pageViewListaProdotti':
                    analyticsData = {
                        event: 'pageview',
                        carrello: {
                            importo,
                            consegnaGratuita,
                            importoConsegna,
                            prodotti: this.localStorage.getFromStorage('carrelloAnalytics') ? this.localStorage.getFromStorage('carrelloAnalytics')['prodotti'] : ''
                        },
                        checkout: {
                            indirizzo: {
                                indirizzoPredefinito: this._deliveryForm ? (this._deliveryForm['defaultAddress'] ? 'SI' : 'NO') : ''
                            }
                        }
                    }

                    step = 'FUNNEL_DATA_CHECKOUT_POPUP_LISTA_PRODOTTI';
                    break;
                //1.1.8
                case 'chiusuraModaleListaProdotti':
                    analyticsData = {
                        event: 'chiusuraModale',
                        carrello: {
                            importo,
                            consegnaGratuita,
                            importoConsegna,
                            prodotti: this.localStorage.getFromStorage('carrelloAnalytics') ? this.localStorage.getFromStorage('carrelloAnalytics')['prodotti'] : ''
                        },
                        checkout: {
                            indirizzo: {
                                indirizzoPredefinito: this._deliveryForm ? (this._deliveryForm['defaultAddress'] ? 'SI' : 'NO') : ''
                            }
                        }
                    }

                    if (selectedDayOnPage && selectedTimeSlotOnPage && giornoPropostoOnPage) {
                        analyticsData['funnel'] = {};
                        analyticsData['funnel']['sceltaSlot'] = {
                            giorno: selectedDayOnPage,
                            fasciaOraria: selectedTimeSlotOnPage,
                            giornoProposto: giornoPropostoOnPage,
                        }
                    }

                    if(tsdEnabled){
                        analyticsData.funnel.sceltaSlot['slotDinamico'] = slotDinamicoOnPage;
                        analyticsData.funnel.sceltaSlot['slotDinamicoScontato'] = slotDinamicoScontatoOnPage;
                    }

                    step = 'FUNNEL_DATA_CHECKOUT_POPUP_LISTA_PRODOTTI';
                    break;
                //1.1.9
                case 'clickPagaOnline':
                    analyticsData = {
                        event: eventType,
                        carrello: {
                            importo,
                            consegnaGratuita,
                            importoConsegna,
                            prodotti: this.localStorage.getFromStorage('carrelloAnalytics') ? this.localStorage.getFromStorage('carrelloAnalytics')['prodotti'] : ''
                        },
                        checkout: {
                            indirizzo: {
                                indirizzoPredefinito: this._deliveryForm ? (this._deliveryForm['defaultAddress'] ? 'SI' : 'NO') : ''
                            }
                        }
                    }

                    if (selectedDayOnPage && selectedTimeSlotOnPage && giornoPropostoOnPage) {
                        analyticsData['funnel'] = {};
                        analyticsData['funnel']['sceltaSlot'] = {
                            giorno: selectedDayOnPage,
                            fasciaOraria: selectedTimeSlotOnPage,
                            giornoProposto: giornoPropostoOnPage,
                        }
                    }

                    if(tsdEnabled){
                        analyticsData.funnel.sceltaSlot['slotDinamico'] = slotDinamicoOnPage;
                        analyticsData.funnel.sceltaSlot['slotDinamicoScontato'] = slotDinamicoScontatoOnPage;
                    }

                    step = 'FUNNEL_DATA_CHECKOUT_CLICK_PAGA_ONLINE';
                    break;
                //1.1.10
                case 'clickPagaInNegozio':
                    analyticsData = {
                        event: eventType,
                        carrello: {
                            importo,
                            consegnaGratuita,
                            importoConsegna,
                            prodotti: this.localStorage.getFromStorage('carrelloAnalytics') ? this.localStorage.getFromStorage('carrelloAnalytics')['prodotti'] : ''
                        },
                        checkout: {
                            indirizzo: {
                                indirizzoPredefinito: this._deliveryForm ? (this._deliveryForm['defaultAddress'] ? 'SI' : 'NO') : ''
                            }
                        }
                    }

                    if (selectedDayOnPage && selectedTimeSlotOnPage && giornoPropostoOnPage) {
                        analyticsData['funnel'] = {};
                        analyticsData['funnel']['sceltaSlot'] = {
                            giorno: selectedDayOnPage,
                            fasciaOraria: selectedTimeSlotOnPage,
                            giornoProposto: giornoPropostoOnPage,
                        }
                    }

                    if(tsdEnabled){
                        analyticsData.funnel.sceltaSlot['slotDinamico'] = slotDinamicoOnPage;
                        analyticsData.funnel.sceltaSlot['slotDinamicoScontato'] = slotDinamicoScontatoOnPage;
                    }

                    step = 'FUNNEL_DATA_CHECKOUT_CLICK_PAGA_IN_NEGOZIO';
                    break;
                //1.2.3
                case 'pageviewPopupPrenotazioneScaduta':
                    analyticsData = {
                        event: 'pageview',
                        carrello: {
                            importo,
                            consegnaGratuita,
                            importoConsegna,
                            prodotti: this.localStorage.getFromStorage('carrelloAnalytics') ? this.localStorage.getFromStorage('carrelloAnalytics')['prodotti'] : ''
                        },
                        checkout: {
                            indirizzo: {
                                indirizzoPredefinito: this._deliveryForm ? (this._deliveryForm['defaultAddress'] ? 'SI' : 'NO') : ''
                            }
                        }
                    }

                    if (selectedDayOnPage && selectedTimeSlotOnPage && giornoPropostoOnPage) {
                        analyticsData['funnel'] = {};
                        analyticsData['funnel']['sceltaSlot'] = {
                            giorno: selectedDayOnPage,
                            fasciaOraria: selectedTimeSlotOnPage,
                            giornoProposto: giornoPropostoOnPage,
                        }
                    }

                    if(tsdEnabled){
                        analyticsData.funnel.sceltaSlot['slotDinamico'] = slotDinamicoOnPage;
                        analyticsData.funnel.sceltaSlot['slotDinamicoScontato'] = slotDinamicoScontatoOnPage;
                    }

                    step = 'FUNNEL_DATA_CHECKOUT_POPUP_PRENOTAZIONE_SCADUTA';
                    break;
                //1.2.4
                case 'clickCTAPrenotaAdessoPrenotazioneScaduta':
                    analyticsData = {
                        event: 'pageview',
                        carrello: {
                            importo,
                            consegnaGratuita,
                            importoConsegna,
                            prodotti: this.localStorage.getFromStorage('carrelloAnalytics') ? this.localStorage.getFromStorage('carrelloAnalytics')['prodotti'] : ''
                        },
                        checkout: {
                            indirizzo: {
                                indirizzoPredefinito: this._deliveryForm ? (this._deliveryForm['defaultAddress'] ? 'SI' : 'NO') : ''
                            }
                        }
                    }

                    if (selectedDayOnPage && selectedTimeSlotOnPage && giornoPropostoOnPage) {
                        analyticsData['funnel'] = {};
                        analyticsData['funnel']['sceltaSlot'] = {
                            giorno: selectedDayOnPage,
                            fasciaOraria: selectedTimeSlotOnPage,
                            giornoProposto: giornoPropostoOnPage,
                        }
                    }

                    if(tsdEnabled){
                        analyticsData.funnel.sceltaSlot['slotDinamico'] = slotDinamicoOnPage;
                        analyticsData.funnel.sceltaSlot['slotDinamicoScontato'] = slotDinamicoScontatoOnPage;
                    }

                    step = 'FUNNEL_DATA_CHECKOUT_POPUP_PRENOTAZIONE_SCADUTA'
                    break;
                
                //1.3.7
                case 'alertCoupon':
                    analyticsData = {
                        event: eventType,
                        form: page,
                        carrello: {
                            importo,
                            consegnaGratuita,
                            importoConsegna,
                            prodotti: this.localStorage.getFromStorage('carrelloAnalytics') ? this.localStorage.getFromStorage('carrelloAnalytics')['prodotti'] : ''
                        },
                    }
                    step = 'FUNNEL_DATA_CHECKOUT_ALERT_COUPON';
                    break;
                //1.3.8
                case 'espandiCoupon':
                    analyticsData = {
                        event: eventType,
                        carrello: {
                            importo,
                            consegnaGratuita,
                            importoConsegna,
                            prodotti: this.localStorage.getFromStorage('carrelloAnalytics') ? this.localStorage.getFromStorage('carrelloAnalytics')['prodotti'] : ''
                        },
                    }
                    step = 'FUNNEL_DATA_CHECKOUT_SECTION_COUPON_ESPANDI';
                    break;
                case 'erroreForm':
                    //4.3.2b
                    let lista_campi_errore = [];
                    if (!_$('#email-error-1').hasClass('uk-hidden') || !_$('#email-error-2').hasClass('uk-hidden')) {
                        lista_campi_errore.push('Indirizzo email');
                    }

                    analyticsData = {
                        event: eventType,
                        form: {
                            campoErrore: lista_campi_errore
                        },
                        carrello: {
                            importo,
                            consegnaGratuita,
                            importoConsegna,
                            prodotti: this.localStorage.getFromStorage('carrelloAnalytics') ? this.localStorage.getFromStorage('carrelloAnalytics')['prodotti'] : ''
                        }
                    }
                    step = 'FUNNEL_DATA_CHECKOUT_FORM_ERROR';
                    break;
                case 'erroreFormDatiCheckout':
                    let listaCampiErrore = [];
                    let form = _$(page).closest('form');
                    let inputError =_$(form).find('input.uk-form-danger');
                    for(let i = 0; i < _$(inputError).length; i++){
                        listaCampiErrore.push(
                            _$(inputError).eq(i).siblings('label').html()
                        );
                    }

                    if(_$(page).find('input[name=c_s1_lift].error-required-checkout').length){
                        listaCampiErrore.push('Ascensore');
                    }

                    if(_$(page).find('select[name=c_s1_floor].uk-form-danger').length){
                        listaCampiErrore.push('Piano');
                    }

                    analyticsData = {
                        event: 'erroreForm',
                        form: {
                            campoErrore: listaCampiErrore
                        },
                        carrello: {
                            importo,
                            consegnaGratuita,
                            importoConsegna,
                            prodotti: this.localStorage.getFromStorage('carrelloAnalytics') ? this.localStorage.getFromStorage('carrelloAnalytics')['prodotti'] : ''
                        }
                    }
                    step = 'FUNNEL_DATA_CHECKOUT_FORM_CONSEGNA_RITIRO_ERROR';
                    if(listaCampiErrore && listaCampiErrore.length){
                        break;
                    }else{
                        return;
                    }
                case 'salvaIndirizzo':
                    if (page == 'checkout-form-address') {
                        //4.3.6
                        analyticsData = {
                            event: eventType,
                            IDpromozioni: this._analytics.getIDpromozioni(),
                            carrello: {
                                importo,
                                consegnaGratuita,
                                importoConsegna,
                            },
                            checkout: {
                                indirizzo: {
                                    indirizzoPredefinito: this._deliveryForm ? (this._deliveryForm['defaultAddress'] ? 'SI' : 'NO') : ''
                                }
                            }
                        }
                        step = 'FUNNEL_DATA_CHECKOUT_SAVE_ADDRESS';
                    } else if(page == 'contact-details-form'){
                        analyticsData = {
                            event: eventType,
                            carrello: {
                                importo,
                                consegnaGratuita,
                                importoConsegna,
                            },
                            checkout: {
                                indirizzo: {
                                    indirizzoPredefinito: 'NO'
                                }
                            }
                        }
                        step = 'FUNNEL_DATA_CHECKOUT_SAVE_CONTACT_DETAILS';
                    }
                    break;
                case 'prenotaSlot':
                    //4.3.7
                    analyticsData = {
                        event: eventType,
                        IDpromozioni: this._analytics.getIDpromozioni(),
                        funnel: {
                            tipoServizio,
                            sceltaSlot: {
                                giorno: selectedDay,
                                fasciaOraria: selectedTimeSlot
                            }
                        },
                        carrello: {
                            importo,
                            consegnaGratuita,
                            importoConsegna
                        }
                    }

                    if(tsdEnabled){
                        analyticsData.funnel.sceltaSlot['slotDinamico'] = slotDinamico;
                        analyticsData.funnel.sceltaSlot['slotDinamicoScontato'] = slotDinamicoScontato;
                    }
                    step = 'FUNNEL_DATA_CHECKOUT_BOOK_SLOT';
                    break;
                case 'selezionaPagamento':
                    //4.3.8
                    analyticsData = {
                        event: eventType,
                        IDpromozioni: this._analytics.getIDpromozioni(),
                        carrello: {
                            importo,
                            consegnaGratuita,
                            importoConsegna
                        },
                        checkout: {
                            pagamento: {
                                metodoPagamento: page
                            }
                        }
                    }

                    if (selectedDayOnPage && selectedTimeSlotOnPage && giornoPropostoOnPage) {
                        analyticsData['funnel'] = {};
                        analyticsData['funnel']['sceltaSlot'] = {
                            giorno: selectedDayOnPage,
                            fasciaOraria: selectedTimeSlotOnPage,
                            giornoProposto: giornoPropostoOnPage,
                        }
                    }

                    if(tsdEnabled){
                        analyticsData.funnel.sceltaSlot['slotDinamico'] = slotDinamicoOnPage;
                        analyticsData.funnel.sceltaSlot['slotDinamicoScontato'] = slotDinamicoScontatoOnPage;
                    }
                    this.localStorage.setItem('metodoPagamento', JSON.stringify(page));
                    step = 'FUNNEL_DATA_CHECKOUT_SELECT_PAYMENT';
                    break;
                case 'salvaDatiFattura':
                    //4.3.30
                    analyticsData = {
                        event: eventType,
                        carrello: {
                            importo,
                            consegnaGratuita,
                            importoConsegna
                        },
                        checkout: {
                            fatturazione: {
                                tipologiaIntestatario: _$('.selection-invoice-type .uk-input') ? _$('.selection-invoice-type .uk-input').html() : '',
                                indirizzoPreferito: _$('#envoiceForm #f_defaultBillingAddress').attr('value') == 'off' ? 'NO' : 'SI'
                            }
                        }
                    }
                    this.localStorage.setItem('tipoIntest',JSON.stringify(analyticsData.checkout.fatturazione.tipologiaIntestatario));
                    this.localStorage.setItem('indirizzoPref',JSON.stringify(analyticsData.checkout.fatturazione.indirizzoPreferito));
                    step = 'FUNNEL_DATA_CHECKOUT_MODAL_FATT_ELETTR_SAVE';
                    break;
                case 'flagFattura':
                    //4.3.31
                    analyticsData = {
                        event: eventType,
                        carrello: {
                            importo,
                            consegnaGratuita,
                            importoConsegna,
                            prodotti : this.localStorage.getFromStorage('carrelloAnalytics') ? this.localStorage.getFromStorage('carrelloAnalytics')['prodotti'] : ''                        
                        },
                        checkout: {
                            fatturazione: {
                                flagRichiesta: _$('#checkout_envoice_input').is(':checked') ? 'SI' : 'NO',
                                tipologiaIntestatario: this.localStorage.getFromStorage('tipoIntest'),
                                indirizzoPreferito: this.localStorage.getFromStorage('indirizzoPref')
                            }
                        }
                    }
                    step = 'FUNNEL_DATA_CHECKOUT_FLAG_FATT_ELETTR';
                    break;
                case 'chiusuraModale':
                    //4.3.12
                    analyticsData = {
                        event: eventType,
                        carrello: {
                            importo,
                            consegnaGratuita,
                            importoConsegna
                        },
                        checkout: {
                            pagamento: {
                                metodoPagamento: this.localStorage.getFromStorage('metodoPagamento')
                            }
                        }
                    }
                    step = 'FUNNEL_DATA_CHECKOUT_CLOSE_MODAL';
                    break;
                case 'sceltaModalitaModificaIndirizzo':
                    //1.8.1
                    analyticsData = {
                        event: eventType,
                        carrello: {
                            importo,
                            consegnaGratuita,
                            importoConsegna
                        }
                    }
                    step = 'FUNNEL_DATA_CHECKOUT_CHANGE_FROM_ADDRESS_BOOK';
                    break;
                case 'modificaIndirizzo':
                    //4.3.13
                    analyticsData = {
                        event: eventType,
                        carrello: {
                            importo,
                            consegnaGratuita,
                            importoConsegna
                        }
                    }
                    step = 'FUNNEL_DATA_CHECKOUT_CHANGE_ADDRESS';
                    break;
                case 'modificaSlot':
                    //4.3.14
                    analyticsData = {
                        event: eventType,
                        carrello: {
                            importo,
                            consegnaGratuita,
                        }
                    }
                    step = 'FUNNEL_DATA_CHECKOUT_CHANGE_SLOT';
                    break;
                case 'modificaMetodoPagamento':
                    //4.3.15
                    analyticsData = {
                        event: 'modificaIndirizzo',
                        carrello: {
                            importo,
                            consegnaGratuita,
                            importoConsegna
                        }
                    }
                    step = 'FUNNEL_DATA_CHECKOUT_CHANGE_BILLING_ADDRESS';
                    break;
                case 'aggiungiIndirizzo':
                    //4.3.17
                    analyticsData = {
                        event: eventType,
                        carrello: {
                            importo,
                            consegnaGratuita,
                            importoConsegna
                        }
                    }
                    step = 'FUNNEL_DATA_CHECKOUT_ADD_ADDRESS';
                    break;
                case 'selezionaIndirizzo':
                    //4.3.18
                    analyticsData = {
                        event: eventType,
                        carrello: {
                            importo,
                            consegnaGratuita,
                            importoConsegna
                        }
                    }
                    step = 'FUNNEL_DATA_CHECKOUT_SELECTED_ADDRESS';

                    break;
                case 'applicaCoupon':
                    //4.3.32
                    analyticsData = {
                        event: eventType,
                        IDpromozioni: this._analytics.getIDpromozioni(),
                        carrello: {
                            importo,
                            consegnaGratuita,
                            importoConsegna,
                            prodotti : this.localStorage.getFromStorage('carrelloAnalytics') ? this.localStorage.getFromStorage('carrelloAnalytics')['prodotti'] : ''
                        },
                        checkout: {
                            pagamento: {
                                codiceCoupon: page
                            }
                        },
                    }
                    step = 'FUNNEL_DATA_CHECKOUT_COUPON_APPLY';
                    break;
                case 'rimuoviCoupon':
                    //4.3.33
                    analyticsData = {
                        event: eventType,
                        IDpromozioni: this._analytics.getIDpromozioni(),
                        carrello: {
                            importo,
                            consegnaGratuita,
                            importoConsegna,
                            prodotti: this.localStorage.getFromStorage('carrelloAnalytics') ? this.localStorage.getFromStorage('carrelloAnalytics')['prodotti'] : ''
                        },
                        checkout: {
                            pagamento: {
                                codiceCoupon: page
                            }
                        },
                    }
                    step = 'FUNNEL_DATA_CHECKOUT_COUPON_REMOVE';
                    break;
                case 'confermaOrdine':
                    // 4.3.34
                    analyticsData = {
                        event: eventType,
                        IDpromozioni: this._analytics.getIDpromozioni(),
                        funnel: {
                            nomeFunnel: 'Checkout',
                            stepFunnel: 'Conferma ordine',
                            tipoServizio
                        },
                        carrello: {
                            importo,
                            consegnaGratuita,
                            importoConsegna,
                            prodotti : this.localStorage.getFromStorage('carrelloAnalytics') ? this.localStorage.getFromStorage('carrelloAnalytics')['prodotti'] : ''
                        },
                        checkout: {
                            indirizzo: {
                                indirizzoPredefinito: this._deliveryForm ? (this._deliveryForm['defaultAddress'] ? 'SI' : 'NO') : ''
                            },
                            pagamento: {
                                metodoPagamento: _$('.section-pagamento').find('input[name="paymentSelected"]:checked').parent().find('span').text().trim(),
                                codiceCoupon
                            },
                            fatturazione: {
                                flagRichiesta: _$('#checkout_envoice_input').is(':checked') ? 'SI' : 'NO',
                                tipologiaIntestatario: this.localStorage.getFromStorage('tipoIntest'),
                                indirizzoPreferito: this.localStorage.getFromStorage('indirizzoPref')
                            }
                        }
                    }

                    if (selectedDayOnPage && selectedTimeSlotOnPage) {
                        analyticsData['funnel']['sceltaSlot'] = {
                            giorno: selectedDayOnPage,
                            fasciaOraria: selectedTimeSlotOnPage,
                        }

                        if(tsdEnabled){
                            analyticsData.funnel.sceltaSlot['slotDinamico'] = slotDinamicoOnPage;
                            analyticsData.funnel.sceltaSlot['slotDinamicoScontato'] = slotDinamicoScontatoOnPage;
                        }

                    } else {
                        if (window['selectedTimeslotExpiration']) {
                            analyticsData['funnel']['sceltaSlot'] = {
                                giorno: window['selectedTimeslot'] ? this._analytics.getDayFromTimestamp(window['selectedTimeslot'].date).toString() : '',
                                fasciaOraria: window['selectedTimeslot'] ? window['selectedTimeslot'].startTime.formattedHour + ' - ' + window['selectedTimeslot'].endTime.formattedHour : '',
                            }

                            if(tsdEnabled){
                                analyticsData = this._analytics.addCampiTsdDinamicoWindow(analyticsData);
                            }
                        }
                    }

                    analyticsData['funnel']['sceltaNegozio'] = {
                        idNegozio: window['pointOfService'] ? window['pointOfService'].name : '',
                        cooperativaNegozio: window['pointOfService'] ? window['pointOfService'].cooperativeId : '',
                        disponibilitaNegozio: window['typeOfService'] && window['pointOfService'] ?
                            (window['typeOfService'] == 'HOME_DELIVERY' ?
                                (window['pointOfService'].hdTemporaryUnavailable == false ? 'Disponibile' : 'Esaurita')
                                :
                                (window['pointOfService'].ocTemporaryUnavailable == false ? 'Disponibile' : 'Esaurita'))
                        : ''
                    };

                    this.localStorage.setItem('trackingThankYou', JSON.stringify(analyticsData));
                    this._analytics.sendAnalytics(analyticsData, '', false, false);

                    return;
                case 'erroreFormApplicaCoupon':
                    //4.3.35
                    analyticsData = {
                        event: 'erroreForm',
                        carrello: {
                            importo,
                            consegnaGratuita,
                            importoConsegna,
                            prodotti : this.localStorage.getFromStorage('carrelloAnalytics') ? this.localStorage.getFromStorage('carrelloAnalytics')['prodotti'] : ''
                        },
                        form: {
                            campoErrore: 'codePromo'
                        }
                    }
                    step = 'FUNNEL_DATA_CHECKOUT_COUPON_APPLY';
                    break;
                case 'infoPreautorizzazione':
                    //4.3.37

                    let link = document.location.pathname.split('/');
                    let myPage = link[link.length - 1].split('?')[0];

                    switch (myPage) {
                        case window['urlSite'].home.split('/')[1]:
                            myPage = 'Minicart';
                            break;
                        // case window['urlSite'].cart:
                        //     myPage = 'Carrello';
                        //     break;
                        case window['urlSite'].detailCart.split('/')[1]:
                            myPage = 'Carrello';
                            break;
                        case window['urlSite'].checkout.split('/')[1]:
                            myPage = 'Checkout';
                            break;
                    }
                    analyticsData = {
                        event: eventType,
                        funnel: {
                            nomeFunnel: myPage,
                            stepFunnel: 'Modale informazioni preautorizzazione'
                        },
                        carrello: {
                            importo,
                            consegnaGratuita,
                            importoConsegna,
                            prodotti : this.localStorage.getFromStorage('carrelloAnalytics') ? this.localStorage.getFromStorage('carrelloAnalytics')['prodotti'] : ''
                        }
                    }
                    this._analytics.sendAnalytics(analyticsData, '', false, true);
                    return;
                case 'infoConsegna':
                    //4.3.36

                    analyticsData = {
                        event: eventType,
                        funnel: {
                            nomeFunnel: 'Checkout',
                            stepFunnel: 'Tooltip info consegna',
                        },
                        carrello: {
                            importo,
                            consegnaGratuita,
                            importoConsegna,
                            prodotti: this.localStorage.getFromStorage('carrelloAnalytics') ? this.localStorage.getFromStorage('carrelloAnalytics')['prodotti'] : ''
                        }
                    };
                    this._analytics.sendAnalytics(analyticsData, '', false, true);
                    return;
                case 'pageViewModalModificaIndirizzo':
                //1.8.3
                analyticsData = {
                    event: 'pageview',
                    funnel: {
                        nomeFunnel: 'checkout',
                        stepFunnel: 'Popup modifica indirizzo consegna-ritiro',
                        tipoServizio: tipoServizio,
                        provenienza: 'Checkout',
                        sceltaSlot: {
                            giorno: selectedDayOnPage,
                            fasciaOraria: selectedTimeSlotOnPage
                        }
                    },
                    carrello: {
                        importo,
                        consegnaGratuita,
                        importoConsegna,
                        prodotti: this.localStorage.getFromStorage('carrelloAnalytics') ? this.localStorage.getFromStorage('carrelloAnalytics')['prodotti'] : ''
                    }
                };

                if(tsdEnabled){
                    analyticsData.funnel.sceltaSlot['slotDinamico'] = slotDinamicoOnPage;
                    analyticsData.funnel.sceltaSlot['slotDinamicoScontato'] = slotDinamicoScontatoOnPage;
                }
                
                this._analytics.sendAnalytics(analyticsData, '', false, true);
                return;
                case 'ctaProcedi':
                    //1.8.4
                    analyticsData = {
                        event: eventType,
                        funnel: {
                            nomeFunnel: 'checkout',
                            stepFunnel: 'Popup modifica indirizzo consegna-ritiro',
                            tipoServizio: tipoServizio,
                            sceltaSlot: {
                                giorno: selectedDayOnPage,
                                fasciaOraria: selectedTimeSlotOnPage
                            }
                        },
                        carrello: {
                            importo,
                            consegnaGratuita,
                            importoConsegna,
                            prodotti: this.localStorage.getFromStorage('carrelloAnalytics') ? this.localStorage.getFromStorage('carrelloAnalytics')['prodotti'] : ''
                        }
                    };
                    if(tsdEnabled){
                        analyticsData.funnel.sceltaSlot['slotDinamico'] = slotDinamicoOnPage;
                        analyticsData.funnel.sceltaSlot['slotDinamicoScontato'] = slotDinamicoScontatoOnPage;
                    }
                    this._analytics.sendAnalytics(analyticsData, '', false, true);
                    return;
                case 'ctaAnnulla':
                    //1.8.5
                    analyticsData = {
                        event: eventType,
                        funnel: {
                            nomeFunnel: 'checkout',
                            stepFunnel: 'Popup modifica indirizzo consegna-ritiro',
                            tipoServizio: tipoServizio,
                            provenienza: 'Checkout',
                            sceltaSlot: {
                                giorno: selectedDayOnPage,
                                fasciaOraria: selectedTimeSlotOnPage
                            }
                        },
                        carrello: {
                            importo,
                            consegnaGratuita,
                            importoConsegna,
                            prodotti: this.localStorage.getFromStorage('carrelloAnalytics') ? this.localStorage.getFromStorage('carrelloAnalytics')['prodotti'] : ''
                        }
                    };

                    if(tsdEnabled){
                        analyticsData.funnel.sceltaSlot['slotDinamico'] = slotDinamicoOnPage;
                        analyticsData.funnel.sceltaSlot['slotDinamicoScontato'] = slotDinamicoScontatoOnPage;
                    }

                    this._analytics.sendAnalytics(analyticsData, '', false, true);
                    return;
                case 'chiusuraModalModificaIndirizzo':
                    //1.8.6
                    analyticsData = {
                        event: 'chiusuraModale',
                        funnel: {
                            nomeFunnel: 'checkout',
                            stepFunnel: 'Popup modifica indirizzo consegna-ritiro',
                            tipoServizio: tipoServizio,
                            provenienza: 'Checkout',
                            sceltaSlot: {
                                giorno: selectedDay,
                                fasciaOraria: selectedTimeSlot
                            }
                        },
                        carrello: {
                            importo,
                            consegnaGratuita,
                            importoConsegna,
                            prodotti: this.localStorage.getFromStorage('carrelloAnalytics') ? this.localStorage.getFromStorage('carrelloAnalytics')['prodotti'] : ''
                        }
                    };

                    if(tsdEnabled){
                        analyticsData.funnel.sceltaSlot['slotDinamico'] = slotDinamicoOnPage;
                        analyticsData.funnel.sceltaSlot['slotDinamicoScontato'] = slotDinamicoScontatoOnPage;
                    }
                    
                    this._analytics.sendAnalytics(analyticsData, '', false, true);
                    return;
                case 'selezionaIndirizzoSalvato':
                    analyticsData = {
                        event: eventType,
                        funnel: {
                            nomeFunnel: 'Checkout',
                            stepFunnel: 'CTA seleziona indirizzo dalla rubrica',
                        },
                        carrello: {
                            importo,
                            consegnaGratuita,
                            importoConsegna,
                            prodotti: this.localStorage.getFromStorage('carrelloAnalytics') ? this.localStorage.getFromStorage('carrelloAnalytics')['prodotti'] : ''
                        }
                    };
                    this._analytics.sendAnalytics(analyticsData, '', false, true);
                    return;
                case 'cancellaIndirizzo':
                    //1.8.11
                    analyticsData = {
                        event: eventType,
                        funnel: {
                            nomeFunnel: 'Checkout',
                            stepFunnel: 'Step 2 - Scelta Negozio',
                            tipoServizio: tipoServizio,
                            provenienza: 'Checkout',

                        },
                        carrello: {
                            importo,
                            consegnaGratuita,
                            importoConsegna,
                            prodotti: this.localStorage.getFromStorage('carrelloAnalytics') ? this.localStorage.getFromStorage('carrelloAnalytics')['prodotti'] : ''
                        }
                    };
                    this._analytics.sendAnalytics(analyticsData, '', false, true);
                    return;
                case 'pageviewModalQuantitaAggiornata':
                    analyticsData = {
                        event: 'pageview',
                    }
                    step = 'FUNNEL_DATA_CHECKOUT_POPUP_QUANTITA_AGGIORNATA';
                    break;
                case 'backCarrelloAggiornamentoProdotti':
                    analyticsData = {
                        event: eventType,
                    }
                    step = 'FUNNEL_DATA_CHECKOUT_POPUP_QUANTITA_AGGIORNATA_BACK_CARRELLO';
                    break;
                case 'chiudiPopupAggiornamentoProdotti':
                    analyticsData = {
                        event: eventType,
                    }
                    step = 'FUNNEL_DATA_CHECKOUT_POPUP_QUANTITA_AGGIORNATA_CHIUDI';
                    break;
            }
            if (analyticsData) {
                this._analytics.sendAnalytics(analyticsData, step);
            }
        } catch (error) {
            console.log(eventType + ' : ' + error);
        }
    }
}